import React, { useState, useRef } from "react";
import { gql, useQuery } from "@apollo/client";
import { AgGridReact } from "ag-grid-react";
import { Spin, Input, Pagination, Button } from "antd";
import { SearchOutlined, DownloadOutlined } from "@ant-design/icons";
import Papa from "papaparse";

const { Search } = Input;

const WorkflowUsageStats = gql`
  query workflowUsageStatsOfAllTeams(
    $limit: Int
    $offset: Int
    $search_term: String
  ) {
    workflowUsageStatsOfAllTeams(
      limit: $limit
      offset: $offset
      search_term: $search_term
    ) {
      data {
        team_id
        current_billing_start
        billing_actions_run
        last_action_run_date
        actions_run_today
        lifetime_actions_run
        next_billing_date
        billing_limit
        extra_limit
        extra_actions_run
        billing_period_days
        daily_limit
      }
      total
    }
  }
`;

const formatDate = (dateString) => {
  if (!dateString) return "N/A";
  const date = new Date(dateString);
  return (
    `${String(date.getUTCDate()).padStart(2, "0")}/` +
    `${String(date.getUTCMonth() + 1).padStart(2, "0")}/` +
    `${date.getUTCFullYear()} ` +
    `${String(date.getUTCHours()).padStart(2, "0")}:` +
    `${String(date.getUTCMinutes()).padStart(2, "0")}:` +
    `${String(date.getUTCSeconds()).padStart(2, "0")}`
  );
};

const AutomationUsageDashboard = () => {
  const [limit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const gridRef = useRef(null);

  const { data, refetch } = useQuery(WorkflowUsageStats, {
    variables: { limit, offset, search_term: searchTerm || null },
    fetchPolicy: "cache-and-network",
    onCompleted: () => setLoading(false),
  });

  const stats = data?.workflowUsageStatsOfAllTeams?.data || [];
  const totalRecords = data?.workflowUsageStatsOfAllTeams?.total || 0;

  const handleSearch = (value) => {
    setLoading(true);
    setSearchTerm(value.trim());
    setOffset(0);
    refetch({ limit, offset: 0, search_term: value.trim() || null }).finally(
      () => setLoading(false)
    );
  };

  const handlePageChange = (page) => {
    setLoading(true);
    const newOffset = (page - 1) * limit;
    setOffset(newOffset);
    refetch({
      limit,
      offset: newOffset,
      search_term: searchTerm || null,
    }).finally(() => setLoading(false));
  };
  const downloadCSV = async () => {
    setLoading(true);

    try {
      const { data } = await refetch({
        limit: totalRecords,
        offset: 0,
        search_term: searchTerm || null,
      });
      const allStats = data?.workflowUsageStatsOfAllTeams?.data || [];

      if (allStats.length === 0) {
        setLoading(false);
        return;
      }

      // Mapping API field names to readable column names
      const formattedData = allStats.map(
        ({
          __typename,
          team_id,
          current_billing_start,
          billing_actions_run,
          last_action_run_date,
          actions_run_today,
          lifetime_actions_run,
          next_billing_date,
          billing_limit,
          extra_limit,
          extra_actions_run,
          billing_period_days,
          daily_limit,
        }) => ({
          "Team ID": team_id,
          "Billing Start Date": formatDate(current_billing_start),
          "Billing Run Actions": billing_actions_run,
          "Last Run Recorded Action Date": formatDate(last_action_run_date),
          "Actions Run Today": actions_run_today,
          "Total Cumulative Run Actions": lifetime_actions_run,
          "Next Billing Date": formatDate(next_billing_date),
          "Billing Limit": billing_limit,
          "Extra Limit": extra_limit,
          "Extra Actions Run": extra_actions_run,
          "Billing Period (Days)": billing_period_days,
          "Daily Limit": daily_limit,
        })
      );

      const csv = Papa.unparse(formattedData);
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", "Automation Usage Report.csv");
      link.click();
    } catch (error) {
      console.error("Error downloading CSV:", error);
    } finally {
      setLoading(false);
    }
  };

  const columnDefs = [
    { headerName: "Team", field: "team_id", sortable: true, resizable: true },
    {
      headerName: "Billing Start Date",
      field: "current_billing_start",
      sortable: true,
      resizable: true,
      valueFormatter: ({ value }) => formatDate(value),
    },
    {
      headerName: "Billing Run Actions",
      field: "billing_actions_run",
      sortable: true,
      resizable: true,
    },
    {
      headerName: "Last Run Recorded Action Date",
      field: "last_action_run_date",
      sortable: true,
      resizable: true,
      valueFormatter: ({ value }) => formatDate(value),
    },
    {
      headerName: "Actions Run Since Last Record Date",
      field: "actions_run_today",
      sortable: true,
      resizable: true,
    },
    {
      headerName: "Total Cumulative Run Actions",
      field: "lifetime_actions_run",
      sortable: true,
      resizable: true,
    },
    {
      headerName: "Next Billing Date",
      field: "next_billing_date",
      sortable: true,
      resizable: true,
      valueFormatter: ({ value }) => formatDate(value),
    },
    {
      headerName: "Billing Limit",
      field: "billing_limit",
      sortable: true,
      resizable: true,
    },
    {
      headerName: "Extra Limit",
      field: "extra_limit",
      sortable: true,
      resizable: true,
    },
    {
      headerName: "Extra Actions Run",
      field: "extra_actions_run",
      sortable: true,
      resizable: true,
    },
    {
      headerName: "Billing Period (Days)",
      field: "billing_period_days",
      sortable: true,
      resizable: true,
    },
    {
      headerName: "Daily Limit",
      field: "daily_limit",
      sortable: true,
      resizable: true,
    },
  ];

  return (
    <div className="p-6 max-w-screen-xl mx-auto">
      <h4 className="text-2xl font-semibold mb-4 text-left mb-5">
        Automation Usage Report
      </h4>

      {/* Toolbar */}
      <div className="d-flex justify-content-between align-items-center mb-4">
        <Search
          placeholder="Search..."
          allowClear
          enterButton={<SearchOutlined />}
          onSearch={handleSearch}
          style={{ width: 350 }}
        />

        <Button
          type="default"
          icon={<DownloadOutlined />}
          onClick={downloadCSV}
        >
          Download CSV
        </Button>
      </div>

      {/* Loading Spinner */}
      {loading && (
        <div className="w-100 d-flex justify-content-center align-items-center mt-5">
          <Spin size="large" />
        </div>
      )}

      {/* Ag-Grid Table */}
      {!loading && stats.length > 0 && (
        <div
          className="ag-theme-alpine shadow-md rounded-lg bg-white"
          style={{ width: "100%" }}
        >
          <AgGridReact
            ref={gridRef}
            rowData={stats}
            columnDefs={columnDefs}
            defaultColDef={{
              resizable: true,
              sortable: true,
              filter: true,
            }}
            domLayout="autoHeight"
            pagination={false}
          />
        </div>
      )}

      {/* Pagination */}
      {!loading && stats.length > 0 && (
        <div className="mt-4 flex justify-center">
          <Pagination
            current={offset / limit + 1}
            pageSize={limit}
            total={totalRecords}
            onChange={handlePageChange}
            showSizeChanger={false}
          />
        </div>
      )}

      {/* No Data Available */}
      {!loading && stats.length === 0 && (
        <p className="text-gray-500 text-lg text-center py-10 mt-5">
          No data available
        </p>
      )}
    </div>
  );
};

export default AutomationUsageDashboard;
