import { ModalFooter } from "./ModalFooter";
import { useEffect, useState } from "react";
import {Tooltip} from 'react-tooltip'; // Import ReactTooltip

const DropDownSettings = ({ elementid, maxLengthofLabel, handleCloseModalClick, board, elementSettingsData, IsaList, osaList,AdminList }) => {
  const ElementName = board?.find((item) => String(item?.elementid) === String(elementid))?.name;

  const checkOption = () => {
    if (String(elementid) === "DropDown-opp_isa") {
      return IsaList?.map((item, index) => ({ id: index, value: item, isCheck: false }));
    } else if (String(elementid) === "DropDown-opp_assigned_osa") {
      return osaList?.map((item, index) => ({ id: index, value: item, isCheck: false }));
    }else if (String(elementid) === "DropDown-Admin") {
      return AdminList?.map((item, index) => ({ id: index, value: item.name, isCheck: false }));
    }else if (String(elementid) === "DropDown-assigned_to") {
      const combinedList = [...(IsaList || []), ...(osaList || [])];
      return combinedList?.map((item, index) => ({ id: index, value: item, isCheck: false }));
    }

    else {
      return [  { id: 1, value: "", isCheck: true },
        { id: 2, value: "Option 1", isCheck: false },
        { id: 3, value: "Option 2", isCheck: false }];
    }
  };

  const initialvalue = {
    options: checkOption(),
    label: ElementName,
    size: "large",
    inputType: "text",
    required: false,
    minValue: "",
    maxValue: "",
    instructions: "",
  };

  const showingFieldId = /\d/.test(elementid);

  const CheckDisableField = (elementid) => {
    return String(elementid) === "DropDown-opp_isa" || String(elementid) === "DropDown-stage" || String(elementid) === "DropDown-opp_assigned_osa" || String(elementid) === "DropDown-Admin" || String(elementid) === "DropDown-isas" || String(elementid) === "DropDown-assigned_to" || String(elementid) === "DropDown-app-type" || String(elementid) === "DropDown-appt_outcome";
  };

  const [Dropdown, setDropDown] = useState(
    Object.keys(elementSettingsData)?.length !== 0 ? elementSettingsData : initialvalue
  );
  const [showErrorInFooter, setshowErrorInFooter] = useState(true);

  const handlerAddNewOption = () => {
    setshowErrorInFooter(true);
    const id = Math.floor(Math.random() * 1000);
    const values = [...Dropdown.options];
    values?.push({ id: id, value: "", isCheck: false });
    setDropDown({ ...Dropdown, options: values });
  };

  const handleInputChange = (e) => {
    const name = e.target.name;
    let value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
  
    if (String(name) === "label" && value.trim() === "") {
      value = ElementName; // Set the default value to the element name
    }
  
    setDropDown({ ...Dropdown, [name]: value });
  };
  const handleChangeInAddOption = (event, index) => {
    const { name, value } = event.target;
    const updatedOptions = [...Dropdown.options];
    updatedOptions[index] = {
      ...updatedOptions[index],
      [name]: value,
    };
    setDropDown({ ...Dropdown, options: updatedOptions });
  };

  const handleChangeInAdd = (event) => {
    setshowErrorInFooter(event.target.value === "");
  };

  useEffect(() => {
    const data = [...Dropdown?.options];
    const check = data.slice(1).some((item) => item.value.trim() === "");
        setshowErrorInFooter(check);
  }, [Dropdown.options]);

  const handleDeleteForOptions = (event, index) => {
    event.preventDefault();
    if (CheckDisableField(elementid)) return;
    const values = [...Dropdown.options];
    values.splice(index, 1);
    setDropDown({ ...Dropdown, options: values });
  };
  const handleSelectOnlyOne = (selectedIndex) => {
    setDropDown((prev) => ({
      ...prev,
      options: prev.options.map((option, index) => ({
        ...option,
        isCheck: index === selectedIndex,
      })),
    }));
  };
  
  console.log("Dropdown",Dropdown)
  


  return (
    <>
      <div className="modal-body py-4 px-lg-5 p-md-4">
        <h5 className="mb-4">
          <i className="iconify fs-5 pointer me-1" data-icon="tabler:checkbox" />
          Dropdown Field
        </h5>
        <div className="mb-4">
          <label>
            <span className="d-flex mb-2 align-items-center">
              <span>Dropdown-Field label</span>
            </span>
          </label>
          <input
            className="form-control input-bn"
            type="text"
            name="label"
            maxLength={maxLengthofLabel}
            defaultValue={Dropdown?.label === undefined || Dropdown?.label === "" ? ElementName : Dropdown?.label}
            onBlur={handleInputChange}
            disabled={CheckDisableField(elementid)}
            placeholder="Enter Dropdown-Field label"
          />
        </div>

        {Dropdown?.options?.length > 0 &&
  Dropdown?.options?.map((input, index) => (
    <div key={input?.id}>
      <div className="mb-2">
        <div className="d-flex align-items-center">
          <input
            type="checkbox"
            className="form-check-input me-2"
            checked={input?.isCheck || false}
            onChange={() => handleSelectOnlyOne(index)}
            disabled={CheckDisableField(elementid)}
          />
          <input
            className="form-control input-bn"
            type="text"
            defaultValue={input?.value || ""}
            required
            id={input?.id}
            name="value"
            placeholder={index === 0 ? "" : "Enter option"}
            onBlur={(event) => handleChangeInAddOption(event, index)}
            onChange={(event) => handleChangeInAdd(event, index)}
            disabled={CheckDisableField(elementid)}
          />
          <span
            onClick={(e) => handleDeleteForOptions(e, index)}
            data-tooltip-id="my-tooltip"
            data-tooltip-content="This option can't be deleted"
            data-tooltip-place="top"
          >
            <i
              className={`iconify fs-5 text-danger pointer ms-2 ${CheckDisableField(elementid) ? "disabled" : ""}`}
              data-icon="mdi:trash"
            />
          </span>
          <Tooltip id="my-tooltip" />
        </div>
      </div>
    </div>
  ))}

        <div className="d-flex align-items-center mt-4">
          <i className="iconify fs-5 pointer text-secondary mx-1" data-icon="ci:plus" />
          <button
            className="btn btn-primary rounded-pill"
            onClick={handlerAddNewOption}
            disabled={CheckDisableField(elementid)}
          >
            Add new option
          </button>
        </div>

        {(String(elementid) === "DropDown-opp_isa" || String(elementid) === "DropDown-opp_assigned_osa") && (
          <span>
            These drop options are controlled by <a href="https://datalabz.re/user/fub_user_list" target="_blank" className="oppstyle" rel="noopener noreferrer">FUB User List</a>
          </span>
        )}

        <hr className="hr my-5" />
        <h5 className="mb-4">
          <i className="iconify fs-5 pointer me-1" data-icon="bx:slider" />
          General Settings
        </h5>

        <div className="mt-4">
          <div className="mb-4">
            <label className="w-100">
              <span className="mb-2 d-flex justify-content-between align-items-center">
                <span className="me-2">Size</span>
              </span>
            </label>
            <select
              className="form-select input-bn"
              value={Dropdown?.size || ""}
              name="size"
              onChange={handleInputChange}
            >
              <option value="large">Large</option>
              <option value="medium">Medium</option>
              <option value="small">Small</option>
            </select>
          </div>
        </div>

        <div className="mt-4">
          <div className="mb-4">
            <label className="w-100">
              <span className="mb-2 d-flex justify-content-between align-items-center">
                <span className="me-2">Input type</span>
              </span>
            </label>
            <select
              disabled
              className="form-select input-bn"
              value={Dropdown?.inputType || ""}
              name="inputType"
              onChange={handleInputChange}
            >
              <option value="dropdown">DropDown</option>
            </select>
          </div>
        </div>

        <div className="mb-4">
          <span>Options</span>
          <div className="mt-2 d-flex align-items-center">
            <div className="me-3">
              <input
                className="form-check-input"
                type="checkbox"
                checked={Dropdown?.required || ""}
                onChange={handleInputChange}
                name="required"
                id="check-1"
              />
              <label className="form-check-label ms-1" htmlFor="check-1">
                Required
              </label>
            </div>
            <div className="me-3">
              <input
                className="form-check-input"
                type="checkbox"
                checked={Dropdown?.hidden || ""}
                onChange={handleInputChange}
                name="hidden"
                id="check-10"
              />
              <label className="form-check-label ms-1" htmlFor="check-10">
                Hidden
              </label>
            </div>
          </div>
        </div>

        <div className="mb-4">
          <div className="form-floating w-100 form-group">
            <textarea
              style={{ minHeight: "100px !important" }}
              defaultValue={Dropdown?.instructions || ""}
              name="instructions"
              onBlur={handleInputChange}
              placeholder="Instructions for Users (max 100 char)"
              className="form-control input-bn"
              type="text"
              maxLength="100"
            />
            <label>
              <span className="d-flex align-items-center">
                <i className="iconify me-1 mr-1 fs-5" data-icon="mdi:text" />
                <span>Instructions for Users (max 100 char)</span>
              </span>
            </label>
          </div>
        </div>

        {!showingFieldId && <span className="h6">System Field Id : {showingFieldId ? null : `${elementid}`}</span>}
      </div>
      <ModalFooter
        ElementSettingData={Dropdown}
        handleCloseModalClick={handleCloseModalClick}
        elementid={elementid}
        showErrorInFooter={showErrorInFooter}
      />
    </>
  );
};

export default DropDownSettings;