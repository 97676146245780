import React, { useState, useRef } from "react";
import { gql, useQuery } from "@apollo/client";
import { AgGridReact } from "ag-grid-react";
import { Spin, Input, Pagination, Button, DatePicker } from "antd";
import { SearchOutlined, DownloadOutlined } from "@ant-design/icons";
import Papa from "papaparse";

const { Search } = Input;
const { RangePicker } = DatePicker;

const FormUsageStats = gql`
  query FormUsageStatsOfAllTeams(
    $limit: Int
    $offset: Int
    $search_term: String
    $start_date: String
    $end_date: String
  ) {
    FormUsageStatsOfAllTeams(
      limit: $limit
      offset: $offset
      search_term: $search_term
      start_date: $start_date
      end_date: $end_date
    ) {
      data {
        team_id
        total_forms
        total_entries
        total_fields
        todays_entries
        last_run_date
      }
      total
    }
  }
`;

const formatDate = (dateString) => {
  if (!dateString) return "N/A";
  const date = new Date(dateString);
  return (
    `${String(date.getUTCDate()).padStart(2, "0")}/` +
    `${String(date.getUTCMonth() + 1).padStart(2, "0")}/` +
    `${date.getUTCFullYear()} ` +
    `${String(date.getUTCHours()).padStart(2, "0")}:` +
    `${String(date.getUTCMinutes()).padStart(2, "0")}:` +
    `${String(date.getUTCSeconds()).padStart(2, "0")}`
  );
};

const FormUsageDashboard = () => {
  const [limit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [dateRange, setDateRange] = useState([]);
  const [loading, setLoading] = useState(true);
  const gridRef = useRef(null);

  const { data, refetch } = useQuery(FormUsageStats, {
    variables: {
      limit,
      offset,
      search_term: searchTerm || null,
      start_date: dateRange[0] || null,
      end_date: dateRange[1] || null,
    },
    fetchPolicy: "cache-and-network",
    onCompleted: () => setLoading(false),
  });

  const stats = data?.FormUsageStatsOfAllTeams?.data || [];
  const totalRecords = data?.FormUsageStatsOfAllTeams?.total || 0;

  const handleSearch = (value) => {
    setLoading(true);
    setSearchTerm(value.trim());
    setOffset(0);
    refetch({
      limit,
      offset: 0,
      search_term: value.trim() || null,
      start_date: dateRange[0] || null,
      end_date: dateRange[1] || null,
    }).finally(() => setLoading(false));
  };

  const handleDateChange = (dates, dateStrings) => {
    setDateRange(dateStrings);
    setLoading(true);
    setOffset(0);
    refetch({
      limit,
      offset: 0,
      search_term: searchTerm || null,
      start_date: dateStrings[0] || null,
      end_date: dateStrings[1] || null,
    }).finally(() => setLoading(false));
  };

  const handlePageChange = (page) => {
    setLoading(true);
    const newOffset = (page - 1) * limit;
    setOffset(newOffset);
    refetch({
      limit,
      offset: newOffset,
      search_term: searchTerm || null,
      start_date: dateRange[0] || null,
      end_date: dateRange[1] || null,
    }).finally(() => setLoading(false));
  };

  const downloadCSV = async () => {
    setLoading(true);
    try {
      const { data } = await refetch({
        limit: totalRecords,
        offset: 0,
        search_term: searchTerm || null,
      });
      const allStats = data?.FormUsageStatsOfAllTeams?.data || [];

      if (allStats.length === 0) {
        setLoading(false);
        return;
      }

      // Mapping API field names to proper column names
      const formattedData = allStats.map(
        ({
          __typename,
          team_id,
          total_forms,
          total_entries,
          total_fields,
          todays_entries,
          last_run_date,
        }) => ({
          "Team ID": team_id,
          "Total Forms": total_forms,
          "Total Form Entries": total_entries,
          "Total Fields": total_fields,
          "Today's Total Entries": todays_entries,
          "Last Run Date": last_run_date,
        })
      );

      const csv = Papa.unparse(formattedData);
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", "Form Usage Report.csv");
      link.click();
    } catch (error) {
      console.error("Error downloading CSV:", error);
    } finally {
      setLoading(false);
    }
  };

  const columnDefs = [
    { headerName: "Team", field: "team_id", sortable: true, resizable: true },
    {
      headerName: "Total Forms",
      field: "total_forms",
      sortable: true,
      resizable: true,
    },
    {
      headerName: "Total Form Entries",
      field: "total_entries",
      sortable: true,
      resizable: true,
    },
    {
      headerName: "Total Fields",
      field: "total_fields",
      sortable: true,
      resizable: true,
    },
    {
      headerName: "Today's Entries",
      field: "todays_entries",
      sortable: true,
      resizable: true,
    },
    {
      headerName: "Last Run Date",
      field: "last_run_date",
      sortable: true,
      resizable: true,
      valueFormatter: ({ value }) => formatDate(value),
    },
  ];

  return (
    <div className="p-6 max-w-screen-xl mx-auto">
      <h4 className="text-2xl font-semibold mb-4 text-left mb-5">
        Form Usage Report
      </h4>

      {/* Toolbar */}
      <div className="d-flex justify-content-between align-items-center mb-4">
        <div className="d-flex gap-2">
          <Search
            placeholder="Search..."
            allowClear
            enterButton={<SearchOutlined />}
            onSearch={handleSearch}
            style={{ width: 250 }}
          />
          <RangePicker onChange={handleDateChange} />
        </div>
        <Button
          type="default"
          icon={<DownloadOutlined />}
          onClick={downloadCSV}
        >
          Download CSV
        </Button>
      </div>

      {/* Loading Spinner */}
      {loading && (
        <div className="w-100 d-flex justify-content-center align-items-center mt-5">
          <Spin size="large" />
        </div>
      )}

      {/* Ag-Grid Table */}
      {!loading && stats.length > 0 && (
        <div
          className="ag-theme-alpine shadow-md rounded-lg bg-white"
          style={{ width: "100%" }}
        >
          <AgGridReact
            ref={gridRef}
            rowData={stats}
            columnDefs={columnDefs}
            defaultColDef={{
              resizable: true,
              sortable: true,
              filter: true,
              flex: 1,
            }}
            domLayout="autoHeight"
            pagination={false}
          />
        </div>
      )}

      {/* Pagination */}
      {!loading && stats.length > 0 && (
        <div className="mt-4 flex justify-center">
          <Pagination
            current={offset / limit + 1}
            pageSize={limit}
            total={totalRecords}
            onChange={handlePageChange}
            showSizeChanger={false}
          />
        </div>
      )}

      {/* No Data Available */}
      {!loading && stats.length === 0 && (
        <p className="text-gray-500 text-lg text-center py-10 mt-5">
          No data available
        </p>
      )}
    </div>
  );
};

export default FormUsageDashboard;
