import React from "react";
import OTCLogo from "../assets/images/otc-logo.png";

const WebhookToOtc = () => {
  return (
    <>
      <div className="d-flex align-items-center card-g bg-white p-3 border rounded-3">
        <div title="Webhook To OTC">
          <img className="me-2 p-0 fs-5" height="20" src={OTCLogo} alt="" />
          <span> Webhook To OTC</span>
        </div>
        <div className="ms-auto">
          <i className="iconify text-secondary" data-icon="mdi:info-circle"></i>
        </div>
      </div>
    </>
  );
};

export default WebhookToOtc;
