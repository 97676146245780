import { gql } from "@apollo/client";

export const CREATE_FORM = gql`
  mutation CreateForm($formData: GraphQLJSON) {
    CreateForm(form_data: $formData) {
      team_id
      form_id
    }
  }
`;

export const createTerminateForm = gql`
  mutation CreateTerminateForm {
    CreateTerminateForm {
      team_id
      form_id
    }
  }
`;

export const getDefaultFeilds = gql`
  mutation CreateDefaultFields {
    CreateDefaultFields {
      response
    }
  }
`;