import "./form-builder-style.css";
import { FormBuilderMiddleBar } from "./FormBuilderMiddleBar";
import { ToastContainer } from "react-toastify";
import { useState, useContext, useEffect, useRef,useMemo } from "react";
import { useParams } from "react-router-dom";
import { DragDropContext } from "react-beautiful-dnd";
import uuidGenerator from "../../../utils/uuidGenerator";
import { boardConetxt } from "./ContextHelper";
import { ElemContext } from "./ContextHelper";
import { ElementContext } from "./SettingContext";
import ElementsSettingsModal from "../../../components/form-builder-components/BoardElementsUI/ElementsSettingsModal";
import FormBuilderLeftSideBar from "./FormBuilderLeftSideBar";
import FormBuilderRightSideBar from "./FormBuilderRightSideBar";
import { gql, useMutation } from "@apollo/client";
import { useQuery, useLazyQuery } from "@apollo/client";
import { Loaderr } from "../../../components/Loaderr";
import useFormApiHelper from "../../../utils/useFormApiHelper";
import bst from "../../../assets/images/interface_analytics.png";
import { DeleteModalComponet } from "../../../components/form-builder-components/BoardElementsUI/DeleteModalComponet";
import Switch from "react-switch";
import { CustomeElementDeleteModal } from "../../../components/CustomeElementDeleteModal";
const UpdateStatus = gql`
  mutation UpdateFormSettings(
    $teamId: String!
    $formId: String
    $status: Boolean
  ) {
    UpdateFormSettings(team_id: $teamId, form_id: $formId, status: $status) {
      team_id
      form_id
    }
  }
`;
const UpdateFormNameAPI = gql`
mutation UpdateFormSettings($teamId: String!, $formId: String, $formName: String) {
  UpdateFormSettings(team_id: $teamId, form_id: $formId, form_name: $formName) {
    team_id
    form_id
  }
}`;
const GET_TEAM_AND_FORM_DETAILS = gql`
  query GET_TEAM_AND_FORM_DETAILS(
    $teamId: String
    $getIsaosaUsersTeamId2: String
    $getFieldsByTeamIdTeamId2: String
  ) {
    GetAdminUsers(team_id: $teamId) {
      id
      name
    }
    GetISAOSAUsers(team_id: $getIsaosaUsersTeamId2) {
      isa_list
      osa_list
    }
    GetFieldsByTeamId(team_id: $getFieldsByTeamIdTeamId2) {
      field_id
      field_name
      field_type
      input_type
      settings
      field_sub_type
      opportunity_field_id
    }
  }
`;

const GetFormDetails = gql`
  query FormData($formId: String) {
    formData(form_id: $formId) {
      form_id
      team_id
      form_name
      form_description
      form_fields
      form_conditions
      status
      form_created_date
      form_updated_date
      update_by
      base_form_template
      color_scheme
      logo_url
      form_type
      pipeline
      stage
      is_this_appt_form
      disp_text
      select_disp_form
      disp_sched_hour
      disp_sched_minutes
      disp_reminder_hour
      disp_reminder_minutes
      make_reminder_rec
      lead_form_redirect_setting
      thank_you_page_message
      redirect_page_timeout
      lead_form_redirect_url
      lead_custom_field_name
      populate_link_for_new_leads
      backfill_link_for_existing_leads
    }
  }
`;

const DashBoardForFormBuilder = () => {
  const [setElement, setSetElement] = useState({});
  const [CustomElement, setCustomElement] = useState([]);
  const [primary, setPrimary] = useState([]);
  const [AllFields, setAllFields] = useState([]);
  const [DeleteField, setDeleteField] = useState(null);
  const [CustomEleDeleteId, setCustomEleDeleteId] = useState({
    elementid: "",
    name: "",
    refetchform: null,
  });

  const [defaultElement, setDefaultElement] = useState([]);

  const [getFormData, setGetFormData] = useState();

  const { board, setboard } = useContext(boardConetxt);
  // const { setCondition } = useContext(conditionContext);
  const [UpdateNameAPI]=useMutation(UpdateFormNameAPI)

  const params = useParams();
  const formNameRef = useRef(null);
  const { CreateFieldFuntion ,GetFubData,GetFubAllData} = useFormApiHelper();

  const { createFieldMutation } = CreateFieldFuntion();
  const [name, setName] = useState("");
  const [isCollapsed, setIsCollapsed] = useState(true);

  const [index, setIndex] = useState(null);
  const [formStatus, setFormStatus] = useState(false);

  const [UpdateFormStatus] = useMutation(UpdateStatus);
  const [showAppointOutCome, setShowAppointOutCome] = useState(false);
  const [showAppointType, setShowAppointType] = useState(false);
  const [showStage, setShowStage] = useState(false);
  
  const boardHasRequiredElements = useMemo(() => {
    return board?.some(el =>
      ["DropDown-app-type", "DropDown-appt_outcome", "DropDown-stage"].includes(String(el?.elementid))
    );
  }, [board]);
  
  useEffect(() => {
    setShowAppointType(board?.some(el => String(el?.elementid) === "DropDown-app-type"));
    setShowAppointOutCome(board?.some(el => String(el?.elementid) === "DropDown-appt_outcome"));
    setShowStage(board?.some(el => String(el?.elementid) === "DropDown-stage"));
  }, [board]);
  
  const { data: fubData } = GetFubAllData("appointmenttypes", !showAppointType);
  const { data: FubAppointOutCome } = GetFubAllData("appointmentoutcomes", !showAppointOutCome);
  const { data: stageData } = GetFubAllData("stages", !showStage);
  
  const appointTypes = useMemo(
    () =>
      fubData?.GetFubAllData?.output?.map(item => ({
        id: item?.id,
        value: item?.name,
        isCheck: false,
      })) || [],
    [fubData]
  );
  
  const appointOutcomes = useMemo(
    () =>
      FubAppointOutCome?.GetFubAllData?.output?.map(item => ({
        id: item?.id,
        value: item?.name,
        isCheck: false,
      })) || [],
    [FubAppointOutCome]
  );
  
  const stages = useMemo(
    () =>
      stageData?.GetFubAllData?.output?.map(item => (

        {
        id: item?.id,
        value: item?.name,
        isCheck: false,
      })) || [],
    [stageData]
  );
  useEffect(() => {
    if (!boardHasRequiredElements) return;
  
    let shouldUpdate = false;
  
    const updatedBoard = board.map(el => {
      if (String(el?.elementid) === "DropDown-app-type") {
        if (JSON.stringify(el?.settings?.options) !== JSON.stringify(appointTypes)) {
          shouldUpdate = true;
          return { ...el, settings: { ...el.settings, options: appointTypes } };
        }
      } else if (String(el?.elementid) === "DropDown-appt_outcome") {
        if (JSON.stringify(el?.settings?.options) !== JSON.stringify(appointOutcomes)) {
          shouldUpdate = true;
          return { ...el, settings: { ...el.settings, options: appointOutcomes } };
        }
      } else if (String(el?.elementid) === "DropDown-stage") {
        if (JSON.stringify(el?.settings?.options) !== JSON.stringify(stages)) {
          shouldUpdate = true;
          return { ...el, settings: { ...el.settings, options: stages } };
        }
      }
      return el;
    });
  
    if (shouldUpdate) {
      setboard(updatedBoard);
    }
  }, [boardHasRequiredElements, board, appointTypes, appointOutcomes, stages]);

  const { loading: formDataLoading, data: formData } = useQuery(
    GetFormDetails,
    {
      variables: { formId: params?.id },

      onCompleted: (data) => {},
    }
  );
  
  const [
    loadTeamAndFormDetails,
    {
      data: GetTeamAndFormDetails,
      loading: GetTeamAndFormDetailsLoading,
      refetch,
    },
  ] = useLazyQuery(GET_TEAM_AND_FORM_DETAILS);

  useEffect(() => {
    if (getFormData?.team_id) {
      loadTeamAndFormDetails({
        variables: {
          teamId: getFormData?.team_id,
          getIsaosaUsersTeamId2: getFormData?.team_id,
          getFieldsByTeamIdTeamId2: getFormData?.team_id,
        },
      });
    }
  }, [getFormData?.team_id, loadTeamAndFormDetails]);

  useEffect(() => {
    // console.log("setboard 1",board)
    let getForm = formData?.formData;
    setGetFormData(getForm);

    setName(getForm?.form_name);
    setFormStatus(getForm?.status);
  }, [formData?.formData, formData?.formData?.form_fields]);

  if (formDataLoading) return <Loaderr />;

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const endIndex = result?.destination?.index;
    const cloneUsers = Array.from(board);

    const getElementFromList = (list, draggableId) =>
      list?.find((item) => item?.elementid === draggableId);

    const insertElementAndSetBoard = (newElement) => {
      // Check if the element already exists in the board
      const isDuplicate = cloneUsers?.some(
        (item) => item?.elementid === newElement?.elementid
      );
      if (!isDuplicate) {
        cloneUsers.splice(endIndex, 0, newElement);
        const uniqueData = Array.from(
          cloneUsers?.reduce((map, item) => map?.set(item?.elementid, item), new Map())?.values()
        );
        setboard(uniqueData);
        // console.log("setboard 2",board)
      }
    };

    let newElement = {
      elementid: result?.draggableId,
      type: result?.draggableId?.split("-")[0],
      settings: {},
    };

    switch (result.source.droppableId) {
      case "DefaultElements":
        const defaultElementData = getElementFromList(
          defaultElement,
          result?.draggableId
        );
        if (defaultElementData) {
          newElement = { ...newElement, ...defaultElementData };
        }
        insertElementAndSetBoard(newElement);
        break;

      case "CustomElements":
        const customElementData = getElementFromList(
          CustomElement,
          result.draggableId
        );
        if (customElementData) {
          newElement = { ...newElement, ...customElementData };
        }
        insertElementAndSetBoard(newElement);
        break;

      case "PrimaryElements":
        const primaryElementData = getElementFromList(
          primary,
          result?.draggableId
        );
        if (primaryElementData) {
          newElement = { ...newElement, ...primaryElementData };
        }
        insertElementAndSetBoard(newElement);
        break;

      case "Elements":
        newElement = {
          elementid: String(uuidGenerator(result.draggableId)?.elementid),
          type: result?.draggableId,
          name: result?.draggableId,
          settings: {},
        };

        createFieldMutation({
          variables: {
            fieldData: {
              field_id: newElement.elementid,
              input_type: newElement.type,
              field_name: newElement.type,
              field_type: "custom",
              field_sub_type: formData?.formData?.form_type
                ?.split(" ")[0]
                ?.toLowerCase(),
              settings: {},
            },
          },
        });
        insertElementAndSetBoard(newElement);
        break;

      default:
        if (result?.source?.index !== result?.destination?.index) {
          const [removed] = cloneUsers?.splice(result.source.index, 1);
          cloneUsers?.splice(endIndex, 0, removed);
          setboard(cloneUsers);
          // console.log("setboard 3",board)
        }
        break;
    }
  };

  const style = {
    overflowY: "auto",
    height: "100vh",
  };
  const show = board?.length > 0 ? style : null;

  const handleRename = () => {
    setIndex(name);
  };
  const handleChange = (e) => {
    e.preventDefault();
    setName(formNameRef.current.value);
    setIndex(formNameRef.current.value);
  };
  const handleSave = (e) => {
    e.preventDefault();
    UpdateNameAPI({
      variables: {
        teamId: String(formData?.formData?.team_id),
        formId: formData?.formData?.form_id,
        formName: name,
      },
    });

    setIndex(null);
  };
  const handleCancel = (e) => {
    e.preventDefault();

    setName(name);
    setIndex(null);
  };
  const handleChangeLogo = () => {
  };

  const handleLiveChange = () => {
    setFormStatus(!formStatus);
    UpdateFormStatus({
      variables: {
        teamId: String(formData?.formData?.team_id),
        formId: formData?.formData?.form_id,
        status: !formStatus,
      },
    });
  };

  return (
    <div style={show} onClick={handleChangeLogo}>
      <DeleteModalComponet DeleteField={DeleteField} formData={formData} />
      <ToastContainer />
      <CustomeElementDeleteModal
        CustomEleDeleteId={CustomEleDeleteId}
        setCustomEleDeleteId={setCustomEleDeleteId}
      />

      <ElementContext.Provider value={{ setSetElement }}>
        <ElemContext.Provider
          value={{
            CustomElement,
            setCustomElement,
            defaultElement,
            setDefaultElement,
            AllFields,
            setAllFields,
            primary,
            setPrimary,
          }}
        >
          <DragDropContext onDragEnd={onDragEnd}>
            <div
              className="main fmc-main p-0"
              style={{ marginTop: "64px", position: "fixed" }}
            >
              <section>
                <div className="d-lg-none builder-stop">
                  <div className="text-center p-5 d-flex flex-column justify-content-center align-items-center">
                    <img
                      src={bst}
                      className="img-fluid col-5 px-2 mb-5"
                      alt=""
                    />
                    <h2>OOPS !!! We are sorry you cant build on mobile</h2>
                    <p>
                      Please kindly open form builder on your desktop or PC to
                      continue using this engine.
                    </p>
                  </div>
                </div>

                <section className="d-flex fm-stack" style={{ width: "100%" }}>
                  <div className=" pre-field col-lg-4 col-xxl-3 bg-white py-3 px-2 p-xl-3 border-end">
                    <FormBuilderRightSideBar
                      formType={formData?.formData?.form_type}
                      setCustomEleDeleteId={setCustomEleDeleteId}
                      teamId={getFormData?.team_id}
                      IsaList={GetTeamAndFormDetails?.GetISAOSAUsers?.isa_list}
                      isCollapsed={isCollapsed}
                      setIsCollapsed={setIsCollapsed}
                      TeamFields={GetTeamAndFormDetails?.GetFieldsByTeamId}
                      loading={GetTeamAndFormDetailsLoading}
                      refetch={refetch}
                    />
                  </div>

                  <div
                    className=" new-field col-lg-4 col-xxl-3 bg-white py-3 px-2 p-xl-3 border-end shadow-lg collapse"
                    id="newF"
                  >
                    <FormBuilderLeftSideBar
                      IsaList={GetTeamAndFormDetails?.GetISAOSAUsers?.isa_list}
                    />
                  </div>

                  <section
                    className="build-contianer col px-5 mb-5 py-5 mx-auto"
                    style={board?.length === 0 ? null : { overflow: "scroll" }}
                  >
                    <div className="container col-xl-10 mx-auto bg-white d-flex align-items p-4 px-xl-5 py-4 mb-3 rounded-3 border">
                      <h5 className=" m-0">
                        {index === name ? (
                          <>
                            <input
                              type="text"
                              className="text pb-1 pt-0 tr-ease"
                              style={{
                                border: "none",
                                borderBottom: "1px solid #dee2e6",
                                outline: "none",
                                padding: "0",
                                margin: "0",
                                fontSize: "14px",
                                textTransform: "capitalize",
                              }}
                              value={name}
                              ref={formNameRef}
                              onChange={handleChange}
                            />
                            <span
                              onClick={(e) => {
                                handleSave(e);
                              }}
                              style={{ color: "green" }}
                            >
                              <i
                                className="iconify pointer me-2 fs-5"
                                data-icon="iconoir:check"
                              
                              ></i>
                            </span>
                            <span
                              onClick={(e) => {
                                handleCancel(e);
                              }}
                              style={{ color: "red" }}
                            >
                              <i
                                className="iconify pointer fs-5"
                                data-icon="iconoir:cancel"
                                // width="25"
                                // height="25"
                              ></i>
                            </span>
                          </>
                        ) : (
                          <>
                            <span>{name}</span>
                          </>
                        )}
                      </h5>
                      {index == null && (
                        <span
                          className="d-flex align-items-center"
                          style={{ marginLeft: "10px" }}
                          onClick={() => {
                            handleRename();
                          }}
                        >
                          <i
                            className="iconify pointer text-secondary"
                            data-icon="iconamoon:edit-fill"
                            width="20"
                            height="20"
                          ></i>
                        </span>
                      )}

                      <div
                        className="d-flex align-items-center"
                        style={{ marginLeft: "auto" }}
                      >
                        <div className=" d-flex form-check form-switch border-end  pe-3">
                          <Switch
                            onChange={handleLiveChange}
                            checked={!!formStatus}
                            handleDiameter={10}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            width={40}
                            className="switch-rect form-check-input ml-3"
                            height={20}
                            offstyle="primary"
                            disabled={GetTeamAndFormDetailsLoading}
                          />

                          <label
                            className="form-check-label"
                            style={{ marginLeft: "20px", marginTop: "4px" }}
                            htmlFor="flexSwitchCheckDefault"
                          >
                           
                            Live
                          </label>
                        </div>

                        <div className="d-flex">
                        
                        </div>
                      </div>
                    </div>
                    <FormBuilderMiddleBar
                      setDeleteField={setDeleteField}
                      getFormData={getFormData}
                      formStatus={formStatus}
                      osaList={GetTeamAndFormDetails?.GetISAOSAUsers?.osa_list}
                      IsaList={GetTeamAndFormDetails?.GetISAOSAUsers?.isa_list}
                      AdminList={GetTeamAndFormDetails?.GetAdminUsers}
                      TeamFields={GetTeamAndFormDetails?.GetFieldsByTeamId}
                      formData={formData}
                      loading={GetTeamAndFormDetailsLoading}
                    />
                  </section>
                </section>
              </section>
            </div>
          </DragDropContext>
          {Object.keys(setElement)?.length !== 0 ? (
            <ElementsSettingsModal
              elementid={setElement?.elementid}
              type={setElement?.type}
              IsaList={GetTeamAndFormDetails?.GetISAOSAUsers?.isa_list}
              osaList={GetTeamAndFormDetails?.GetISAOSAUsers?.osa_list}
              AdminList={GetTeamAndFormDetails?.GetAdminUsers}
            />
          ) : (
            <></>
          )}
        </ElemContext.Provider>
      </ElementContext.Provider>
    </div>
  );
};

export default DashBoardForFormBuilder;
