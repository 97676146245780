import React, { useState, useEffect, useContext } from "react";
import ArrPng from "../../../assets/images/arr.png";
import { WorkFlowSessionInfo } from "../workflows/EditWorkflow";
import TestOtcAction from "./TestOtcAction";
import OtcViewOnly from "actions/view-only-actions/OtcViewOnly";

export const Otc = ({
  action_index,
  action_info,
  setSelected_action,
  workflowHelper,
  action_id,
  selected_action,
  UpdateAction,
}) => {
  const [testAction, setTestAction] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [testAgain, settestAgain] = useState(true);
  const { session, updating } = useContext(WorkFlowSessionInfo);
  const [test_result, set_test_result] = useState(true);
  const [webhookUrl, setWebhookUrl] = useState(
    action_info?.data_mapper?.webhook_url || ""
  );

  const update_data_mapper = (key, value) => {
    UpdateAction({
      data_mapper: { ...action_info?.data_mapper, [key]: value },
    });
  };

  useEffect(() => {
    session?.[action_id]?.action_status === "SUCCESS"
      ? set_test_result(false)
      : set_test_result(true);
  }, [session]);

  useEffect(() => {
    if (session?.[action_id]) {
      setTestAction("test_action_again");
      setShowDetails(true);
    } else {
      setShowDetails(false);
    }
  }, []);

  const inputHandler = (e) => {
    e.preventDefault();
    const value = e.target.value;
    setWebhookUrl(value);
    update_data_mapper("webhook_url", value);
  };

  return (
    <>
      {selected_action !== action_info.action_id ? (
        <div
          className="pointer"
          onClick={() => setSelected_action(action_info.action_id)}
        >
          <OtcViewOnly
            action_index={action_index}
            action_info={action_info}
            test_result={test_result}
          />
        </div>
      ) : (
        <>
          <div className="d-flex justify-content-center">
            <img height="70" src={ArrPng} alt="" />
          </div>
          <div className="dot-border rounded-3 bg-white gx-2 mb-2 p-4 border position-relative">
            {test_result && (
              <div className="d-flex align-items-start mb-3 tooltip-container bvjk">
                <i
                  className="iconify text-danger mns-auto"
                  data-icon="mdi:info-circle"
                ></i>
                <div className="tooltip-text">
                  Please test the action before saving workflow!{" "}
                </div>
              </div>
            )}
            <div className="w-100 d-flex justify-content-between">
              {!action_info.is_default ? (
                <p
                  className="text-decoration-underline wait text-secondary small"
                  onClick={() => {
                    workflowHelper?.change_action_to_unknown(action_id);
                  }}
                >
                  <i className="iconify" data-icon="mdi:arrow-left"></i>Change
                </p>
              ) : (
                <p></p>
              )}
              <span
                className="pointer"
                onClick={() => workflowHelper?.remove_action(action_id)}
              >
                <i
                  className="iconify p-0 fs-5 text-secondary wait"
                  data-icon="mdi:trash"
                ></i>
              </span>
            </div>
            <div className="w-100 mb-4 pointer">
              <div className="d-flex align-items-center bg-white py-3 pb-2">
                <strong> {action_index + ". "}</strong>
                Webhook To OTC
              </div>
            </div>
            <form className="position-relative">
              <div className="mb-4">
                <div className="form-floating w-100 form-group">
                  <input
                    placeholder="Workspace title "
                    className="form-control input-bn"
                    type="text"
                    required
                    name="name"
                    value={webhookUrl}
                    onChange={inputHandler}
                  />
                  <label>
                    <span className="d-flex align-items-center">
                      <i
                        className="iconify me-1 mr-1 fs-5"
                        data-icon="ri:flow-chart"
                      />
                      <span>Webhook URL</span>
                    </span>
                  </label>
                </div>
                {/* placeholder for errors */}
              </div>
            </form>

            {/* {selectAutomation && ( */}
            <div className="w-100 mt-4 modal-header">
              <div className="d-flex align-items-center tooltip-container">
                <i
                  className="iconify p-0 fs-3 me-1 text-primary"
                  data-icon="ic:round-network-check"
                ></i>
                <button
                  className={`d-none d-md-block text-decoration-underline btn btn-outline-none ${
                    updating ? "disabled-test" : ""
                  }`}
                  onClick={() => {
                    if (!updating) {
                      testAction === "tested_action"
                        ? setTestAction("test_action_again")
                        : setTestAction("tested_action");
                      setShowDetails(false);
                      settestAgain(true);
                    }
                  }}
                >
                  {testAction ? "Test Action Again" : "Test Action"}
                </button>
                {updating && (
                  <div className="tooltip-text">
                    Cannot test action when workflow is being updated
                  </div>
                )}
                {test_result && (
                  <p className="text-danger m-3">Please test the action!</p>
                )}
              </div>
              {testAction && testAction !== "test_action" && (
                <button
                  type="button"
                  className=" dropdown-toggle mt-1 btn-outline"
                  data-bs-toggle="collapse"
                  data-bs-target="#test_fub_automation"
                  onClick={() => {
                    setShowDetails(!showDetails);
                    setTestAction("test_action_again");
                    settestAgain(false);
                  }}
                >
                  {showDetails ? "Show previous test details" : "Hide"}
                </button>
              )}
            </div>
            {/* )} */}

            {testAction === "tested_action" && (
              <TestOtcAction
                testAction={testAction}
                setTestAction={setTestAction}
                action_id={action_info.action_id}
                set_test_result={set_test_result}
                setShowDetails={setShowDetails}
                selected_action={selected_action}
                testAgain={testAgain}
              />
            )}
            {!showDetails && testAction === "test_action_again" && (
              <div
                id="test_fub_automation"
                className={`${
                  showDetails ? "show accordion-collapse collapse" : ""
                }`}
              >
                <TestOtcAction
                  testAction={testAction}
                  setTestAction={setTestAction}
                  action_id={action_info.action_id}
                  set_test_result={set_test_result}
                  setShowDetails={setShowDetails}
                  selected_action={selected_action}
                  testAgain={testAgain}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};
export default Otc;
