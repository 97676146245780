import { gql } from '@apollo/client';

export const GET_FORM_TEMPLATES = gql`
  query GetFormTemplates {
    GetFormTemplates {
      statusCode
      message
      data
    }
  }
`;

export const GET_FUB_DATA = gql`
  query GetFubAllData($type: String!) {
    GetFubAllData(type: $type) {
      output
      status
      info
    }
  }
`;

export const CombinedFormStats = gql`
query GetCombinedStats($formIds: [String!]!, $showTemplates: Boolean) {
 getTeamStats(showTemplates: $showTemplates) {
  total_forms
  total_custom_fields
}
  getFormEntryCounts(form_ids: $formIds) {
    form_id
    total_count
    today_count
  }
}
`;
