import React, { useEffect, useState, useContext } from "react";
import { get_action_icon } from "../assets/logos";
import Accordion from "react-bootstrap/Accordion";
import { useRef } from "react";
import { WorkFlowSessionInfo } from "../pages/automation-builder/workflows/EditWorkflow";
import { act } from "react";
import dataDictionary from "utils/AccordianKeywords";


const AccordionReusable = ({ keys, set_insert_value }) => {
  const [searchValue, setSearchValue] = useState("");
  const [activeKey, setActiveKey] = useState([]);
  const activeKeysList = useRef(activeKey);
  const {triggerdetails, formDetails, workflow_helper} = useContext(WorkFlowSessionInfo);
  const formId = triggerdetails.data_mapper?.trigger_id;

  useEffect(() => {
    // Update activeKey when searchValue changes
    if (
      searchValue.trim() === "" ||
      searchValue === "" ||
      searchValue === null ||
      searchValue === " "
    ) {
      setActiveKey([]);
    }
    else{ 
    setActiveKey(activeKeysList.current);
    }

  }, [searchValue]);

  const reversedKeys = Object.entries(keys)
    .reverse()
    .reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});

  const handleSearchChange = (e) => {
    e.preventDefault();
    setSearchValue(e.target.value);
  };

  const handleAccordionItemClick = (index) => {
    // Function to manually expand/collapse accordion items
    const newActiveKey = activeKey.includes(index)
      ? activeKey.filter((key) => key !== index)
      : [...activeKey, index];
  
    setActiveKey(newActiveKey);
  };

  const getKeyDescription = (key) => {
    const regex = /(.*?)\[(\d+)\](.*)/; // Matches indexed keys like "person.addresses[0].country"
    const match = key.match(regex);
    if (match) {
      const [_, baseKey, index, suffix] = match;
      const formattedIndex = parseInt(index, 10) + 1; // Convert to 1-based index
      const newKey = `${baseKey}[0]${suffix}`; // Standardized key to lookup in dictionary
      const baseLabel = dataDictionary[newKey] || key; // Fallback to key if not found
      return `${baseLabel} ${formattedIndex}`.trim(); // Append index dynamically
    }
    return dataDictionary[key] || key; // Return the normal label if no index found
  };

  function searchList(options, searchValue, action_logo, action_name) {
    let formFields = formDetails?.form_fields;

    if (action_name === "trigger") {
        const findName = (elementId) => {
            const element = formFields?.find(el => el.elementid === elementId);
            return element ? element.name : null;
        };
        const namesArray = options?.map(obj => {
          const keyParts = obj?.key?.split("__");
          const elementId = keyParts.length > 1 ? keyParts[3]?.split("}}")[0] : null;
          return elementId ? findName(elementId) : null;
      });
      
        // Replace key_show with values from namesArray
      options.forEach((option, index) => {
          option.key_show = namesArray[index] || option.key_show;
      });
    }

    const filteredOptions = options?.filter((option) => {
        // Check if the key_show is a string before applying string methods
        const keyShowString = option.key_show ? option.key_show.toString() : "";
        const valueString = option.value ? option.value.toString() : "";
        const keyString = option.key ? option.key.toString() : "";

        // Check if the search term is present in either the key or the value
        return (
            keyShowString
                .toLowerCase()
                .split(" ")
                .join("")
                .includes(searchValue.toLowerCase().split(" ").join("")) ||
            valueString
                .toLowerCase()
                .split(" ")
                .join("")
                .includes(searchValue.toLowerCase().split(" ").join("")) ||
            keyString
                .toLowerCase()
                .split(" ")
                .join("")
                .includes(searchValue.toLowerCase().split(" ").join(""))
        );
    });

    const renderedOptions = filteredOptions?.map((option, i) => (
        <div
            key={i}
            className="d-flex align-items-center card-g bg-white p-2 border rounded-2 mx-auto m-2"
            onClick={() => {set_insert_value(option.key);
            }}
            style={{ overflow: "hidden", wordBreak: "break-word" }}
        >
            <img className="me-2 p-0 fs-5" height="20" src={action_logo} alt="" />
            <span>
                <b>{getKeyDescription(option.key_show)}</b>{" "}
                {option?.value != null ? option.value.toString() : "null"}
            </span>
        </div>
    ));

    return (
        <div>
            {renderedOptions?.length !== 0 ? (
                renderedOptions
            ) : (
                <div>No matches found.</div>
            )}
        </div>
    );
}

  // function searchList(options, searchValue, action_logo, action_name) {
    
  //   let formFields = formdata?.formData?.form_fields;
  //   if (action_name === "trigger") {
  //     console.log("options", options, "formdata", formFields, formId);
  //   }
  //   const filteredOptions = options?.filter((option) => {
  //     const keyShowString = option.key_show ? option.key_show.toString() : "";
  //     const valueString = option.value ? option.value.toString() : "";
  //     const keyString = option.key ? option.key.toString() : "";

  //     // Check if the search term is present in either the key or the value
  //     return (
  //       keyShowString
  //         .toLowerCase()
  //         .split(" ")
  //         .join("")
  //         .includes(searchValue.toLowerCase().split(" ").join("")) ||
  //       valueString
  //         .toLowerCase()
  //         .split(" ")
  //         .join("")
  //         .includes(searchValue.toLowerCase().split(" ").join("")) ||
  //       keyString
  //         .toLowerCase()
  //         .split(" ")
  //         .join("")
  //         .includes(searchValue.toLowerCase().split(" ").join(""))
  //     );
  //   });
  
  //   const renderedOptions = filteredOptions?.map((option, i) => {
  //     let fieldName = option.key_show;
  
  //     // Extract field name from keys like "{{trigger__event__event_data__CheckBox-43666[0]}}"
  //     const keyParts = option.key_show.match(/{{.*?__event_data__(.*?)}}/);
  //     if (keyParts && keyParts.length > 1) {
  //       const fieldKey = keyParts[1]; // Extracted field key
  //       console.log("fieldKey.............................", fieldKey);
  //       const field = formFields.find((field) => field.elementid.includes(fieldKey));
  //       if (field) {
  //         fieldName = field.name;
  //       }
  //     }
  
  //     return (
  //       <div
  //         key={i}
  //         className="d-flex align-items-center card-g bg-white p-2 border rounded-2 mx-auto m-2"
  //         onClick={(event) => {
  //           set_insert_value(option.key);
  //         }}
  //       >
  //         <img className="me-2 p-0 fs-5" height="20" src={action_logo} alt="" />
  //         <span>
  //           <b>{fieldName}</b>{" "}
  //           {option?.value != null ? option.value.toString() : "null"}
  //         </span>
  //       </div>
  //     );
  //   });
  
  //   return (
  //     <div>
  //       {renderedOptions?.length !== 0 ? (
  //         renderedOptions
  //       ) : (
  //         <div>No matches found.</div>
  //       )}
  //     </div>
  //   );
  // }
  

  const createAccordion = (data) => {
    let accordion_list = [];
  let activeKeys = [];

const keys = workflow_helper.current.get_actions_by_order().map(({ action_id, automation_name, meta_data }) => ({
  action_id,
  automation_name,
  meta_data
}));

const findAutomationByActionId = (action_id) => {
  const action = keys.find(key => key.action_id === action_id);
  const automation_name = action?.meta_data?.automation_name || action?.automation_name;
  return automation_name ? `: ${automation_name}` : '';
};

    for (const [index, [key, value]] of Object.entries(data).entries()) {
      let action_logo = get_action_icon(key);
      const foundInSearch = searchList(value, searchValue, action_logo, key);
      let automation_name = findAutomationByActionId(key);

      if (foundInSearch && foundInSearch.props.children.length > 0) {
        if (!activeKeys.includes(index.toString())) {
          activeKeys.push(index.toString());
        }
      }
      accordion_list.push(
        <Accordion.Item key={index.toString()} eventKey={index.toString()}>
         <Accordion.Header onClick={() => handleAccordionItemClick(index.toString())}>
           <div className=" card-g bg-white p-1 border rounded-2 m-2">
              <img className="p-0 fs-5" height="20" src={action_logo} alt="" />
            </div>
            <span>
              {" "}
              {index + 1 + "."} {key.replace(/\d+$/, '')}{automation_name}
            </span>
          </Accordion.Header>
          <Accordion.Body>
            <div className="accordion-body">
              {Object.values(value).length > 0 && value[0]?.key_show !== "" ? (
                <div>{searchList(value, searchValue, action_logo, key)}</div>
              ) : (
                <p>
                  Please test the action successfully to get relevant fields!
                </p>
              )}
            </div>
          </Accordion.Body>
        </Accordion.Item>
      );
    }
    activeKeysList.current = activeKeys;
    return accordion_list;
  };

  return (
    <div className="f-bd-ac p-3">
      <div className="search-container">
        <input
          type="search"
          className="form-control search-fgy input-bn"
          style={{ height: "50px !important", display: "block" }}
          placeholder="Search"
          onChange={handleSearchChange}
          value={searchValue}
        />
      </div>
      <div className="mt-2 overflow-y-scroll gx-up">
        <div className="pb-3">
          {activeKeysList.current.length === 0 && searchValue !== "" ?  <div className="text-left">No matches found.</div> : null}
        </div>
        <Accordion activeKey={activeKey}>
          {createAccordion(reversedKeys)}
        </Accordion>
      </div>
    </div>
  );
};

export default AccordionReusable;
