import React, { useContext, useEffect, useState, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { Loaderr } from "./components/Loaderr";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useFormApiHelper from "./utils/useFormApiHelper";
import { FormPlanContext } from "./pages/form-builder/dashboard/ContextHelper";
import { GET_TEAM_SETTINGS }  from "./graphql/queries/teamSettingsQueries"
import { debounce } from "lodash";
import { Spinner } from "assets/images/three-dots-loading";
import { createTerminateForm, getDefaultFeilds } from "./graphql/mutations/formMutations";
import { UpdateFormNameAPI } from "./graphql/mutations/FormNameMutation";
import { CombinedFormStats } from "./graphql/queries/formQueries";
import { Pagelimit } from "utils/constants";


const FormDetails = ({
  totalcount,
  todayCount,
  FormStatusInfo,
  refetch,
  formId,
  formName,
  leadtype,
  teamId,
  DashboardForms,
  setDashboardForms,
  base_form_template,
  form_type,
  deleteForm,
  duplicateFormMutation,
  showTemplate
}) => {
  const NavigateToURL = useNavigate();
  const { GetFormEntries, UpdateFormName } = useFormApiHelper();
  const [FormTitleName, setFormTitleName] = useState(formName);
  const [iframeHTML, setIframeHTML] = useState("");
  const [formStatus] = useState(FormStatusInfo);
  const [isHovered, setHovered] = useState(false);
  // const { duplicateFormMutation, duplicateFormData,duplicateFormLoading } = DuplicateForm();
  const { data } = GetFormEntries(formId);
  const { UpdateFormData } = UpdateFormName();
  // //console.log("npm",typeof(formName))

  const [UpdateFormNameNew] = useMutation(UpdateFormNameAPI, {
    onCompleted: (data) => {
    },
  });

  const handleFormNameEdit = (id) => {
    if (index == null) {
      NavigateToURL(`build/${id}`);
    }
  };

  const handelEntryClick = () => {
    NavigateToURL(`entries/${formId}`, {
      state: { entries: data },
    });
  };

  function copyTextz() {
    const copyText = "https://app.datalabz.re/forms/" + formId;

    const Myicon = <i className="iconify fs-5" data-icon="noto:clipboard"></i>;

    navigator?.clipboard?.writeText(copyText)?.then(() => {
      toast?.success("copied to clipboard!" + copyText, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2500,
        icon: Myicon,
        hideProgressBar: true,
        pauseOnHover: false,
        closeOnClick: true,
        theme: "dark",
      });
    });
  }

  const handleDuplicateChange = (id) => {
    duplicateFormMutation({
      variables: {
        formId: id,
      },
    });
  };

  const [index, setIndex] = useState(null);
  const handleFormRename = (formId) => {
    setIndex(formId);
  };
  const handleFormTitleChange = (e) => {
    setFormTitleName(e.target.value);
  };
  const handleFormTitleSave = (e) => {
    e.preventDefault();
    UpdateFormNameNew({
      variables: {
        teamId: String(teamId),
        formId: formId,
        formName: FormTitleName,
      },
    });
   
    setIndex(null);
  };
  const handleFormDelete = () => {
    deleteForm({
      variables: {
        formId: formId,
      },
    });
    const newForm = DashboardForms?.filter((item) => item?.form_id !== formId);
    refetch();
    setDashboardForms(newForm);
  };
  const handleFormTitleChangeCancel = (e) => {
    e.preventDefault();
    setIndex(null);
    setFormTitleName(formName);
  };
  const hanndleiFrameCopy = () => {
    // Construct the iframe HTML as a string
    const iframeHTML = `
         <iframe
            height="1742"
            title="Embedded Form 2.0"
            allowTransparency="true"
            style="width:100%;border:none"
            sandbox="allow-popups-to-escape-sandbox allow-top-navigation allow-scripts allow-popups allow-forms allow-same-origin"
            src="https://app.datalabz.re/forms/${formId}"
         >
            <a href="https://app.datalabz.re/forms/${formId}">${formName}</a>
         </iframe>
      `;
    setIframeHTML(iframeHTML);

    // Copy the iframe string to the clipboard
    navigator?.clipboard?.writeText(iframeHTML);
  };
  // console.log("formName",leadtype)

  return (
    <div
      className="d-flex flex-wrap border rounded-3 bg-white fm-list-container mb-1 "
      key={formId}
    >
      <div
        className="modal fade"
        id={`exampleModalCenter-${formId}`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered  modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header px-4 border-0">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Embed {formName}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body pb-4 px-4">
              {/* <p className="fs-6">Embed a form on your website</p> */}
              <div className="d-flex align-items-center gap-3 border rounded-3 p-3">
                <div className="d-flex flex-wrap">
                  {iframeHTML}
                </div>
              </div>
              <button
                type="button"
                className="btn btn-primary mt-3"
                data-bs-dismiss="modal"
              >
                Copy to clipboard
                <i className="iconify" data-icon="mage:copy"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`d-flex flex-wrap col-12 col-md-6 align-items-center p-4 border-0 border-end border your ${
          isHovered ? "hovered" : ""
        }`}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <span
          className=" fs-6 p-0 m-0 text-capitalize pointer pint ml-2"
          onClick={(e) => {
            handleFormNameEdit(formId);
          }}
        >
          {String(index) === String(formId) ? (
            <>
              <input
                type="text"
                className="text pb-1 pt-0 tr-ease"
                style={{
                  border: "none",
                  borderBottom: "1px solid #dee2e6",
                  outline: "none",
                  padding: "0",
                  margin: "0",
                  fontSize: "14px",
                  textTransform: "capitalize",
                }}
                value={FormTitleName}
                onChange={handleFormTitleChange}
              />
              <span
                onClick={(e) => {
                  handleFormTitleSave(e);
                }}
                style={{ color: "green" }}
              >
                <i
                  className="iconify me-2 fs-5"
                  data-icon="iconoir:check"
                  // width="25"
                  // height="25"
                ></i>
              </span>
              <span
                onClick={(e) => {
                  handleFormTitleChangeCancel(e);
                }}
                style={{ color: "red" }}
              >
                <i className="iconify fs-5" data-icon="iconoir:cancel"></i>
              </span>
            </>
          ) : (
            <>
              <span>{FormTitleName}</span>
            </>
          )}
        </span>
        <span>
          {index == null && (
            <span
              className="icon"
              style={{ marginLeft: "10px" }}
              onClick={() => {
                handleFormRename(formId);
              }}
            >
              <i
                className="iconify pointer text-secondary"
                data-icon="iconamoon:edit-fill"
                width="22"
                height="20"
              ></i>
            </span>
          )}
        </span>
        <span
          className="badge bg-secondary fs-small fw-normal rounded-pill"
          style={{ marginLeft: "auto" }}
        >
          {form_type === "Lead Forms" && leadtype}
          {form_type === "Opportunity Forms" &&
            (base_form_template || "Custom Form")}
        </span>

        <span
          className="badge bg-primary fs-small fw-normal rounded-pill"
          style={{ marginLeft: "5px" }}
        >
          {form_type}
        </span>
      </div>

      <div
        className="d-flex align-items-center justify-content-center p-4 fs-5 gap-1 col col-md-2 border-0 border-end border pointer pint"
        onClick={() => handelEntryClick()}
      >
        <b>{todayCount || 0}</b>
        <i className="iconify fs-5" data-icon="bx:list-ul" />
      </div>

      <div
        className="d-flex align-items-center justify-content-center p-4 fs-5 gap-1 col col-md-2 border-0 border-end border pointer pint"
        onClick={() => handelEntryClick()}
      >
        <b>{totalcount || 0}</b>
        <i className="iconify fs-5" data-icon="bx:list-ul" />
      </div>

      {formStatus ? (
        <div className="d-flex align-items-center justify-content-center px-4 col col-md-1 border-0 border-end border">
          <span className="p-0 m-0 small">
            {" "}
            <i
              className="iconify"
              style={{ color: "green" }}
              data-icon="fluent:live-20-filled"
            ></i>{" "}
            Live
          </span>
        </div>
      ) : (
        <div className="d-flex align-items-center justify-content-center px-4 col col-md-1 border-0 border-end border">
          <span className="p-0 m-0 small">
            {" "}
            <i
              className="iconify"
              style={{ color: "red" }}
              data-icon="fluent:live-20-filled"
            ></i>{" "}
            Draft
          </span>
        </div>
      )}

      <div className="d-flex align-items-center dropdown justify-content-center p-4 col col-md-1 btnn pointer">
        <div>
          <i
            className="iconify dropdown-toggle fs-3"
            data-icon="fe:elipsis-h"
            data-bs-toggle="dropdown"
          ></i>

          <ul className="dropdown-menu shadow-lg border-0 py-0">
            <li
              id={formId}
              onClick={(e) => {
                handleFormNameEdit(formId);
              }}
            >
              <button className="dropdown-item mb-d2 py-3 d-flex justify-content-between align-items-center">
                Edit
                <i className="iconify" data-icon="clarity:note-edit-line"></i>
              </button>
            </li>

            <NavLink to={`/forms/entries/${formId}`}>
              <li>
                <button className="dropdown-item mb-d2 py-3 d-flex justify-content-between align-items-center">
                  View entries
                  <i className="iconify" data-icon="bx:list-ul"></i>
                </button>
              </li>
            </NavLink>

            <li
              onClick={() => {
                handleDuplicateChange(formId);
              }}
            >
              <button className="dropdown-item mb-d2 py-3 d-flex justify-content-between align-items-center">
                Duplicate
                <i className="iconify" data-icon="fluent:copy-24-regular"></i>
              </button>
            </li>

            <NavLink to={`/forms/${formId}`} target="_blank">
              <li>
                <button className="dropdown-item mb-d2 py-3 d-flex justify-content-between align-items-center">
                  Preview
                  <i className="iconify" data-icon="fluent:eye-24-regular"></i>
                </button>
              </li>
            </NavLink>

            <li className="" onClick={copyTextz}>
              <button className="dropdown-item mb-d2 py-3 d-flex justify-content-between align-items-center">
                Copy Link
                <i className="iconify" data-icon="fluent:link-24-regular"></i>
              </button>
            </li>

            <li onClick={hanndleiFrameCopy}>
              <button className="dropdown-item mb-d2 py-3 d-flex justify-content-between align-items-center">
                <span
                  data-bs-toggle="modal"
                  data-bs-target={`#exampleModalCenter-${formId}`}
                >
                  Embed App
                </span>
                <i className="iconify" data-icon="ion:desktop-outline"></i>
              </button>
            </li>

            {String(formName) === "Terminate Form" || showTemplate  ? (
              <></>
            ) : (
              <li
                onClick={() => {
                  handleFormDelete(formId);
                }}
              >
                <button className="dropdown-item mb-d2 py-3 d-flex justify-content-between align-items-center">
                  Delete
                  <i className="iconify" data-icon="clarity:trash-line"></i>
                </button>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

const FormList = () => {
  const [DashboardForms, setDashboardForms] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [duplicate, setDuplicate] = useState(false);
  const { formPlan } = useContext(FormPlanContext);
  const { DeleteForm, DuplicateForm,GetFormDataForFormList,FormCount } = useFormApiHelper();
  const { deleteForm, deleteFormLoading } = DeleteForm();
  const { duplicateFormMutation, duplicateFormData, duplicateFormLoading }= DuplicateForm();
  const [showTemplate, setShowTemplate] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const {getFormsLoading, getFormsData, fetchMore, refetch } = GetFormDataForFormList("","","",showTemplate);

  const {countTotalFormsData}=FormCount(searchQuery,showTemplate);
  const { data: CombinedFormStatsData } =
    useQuery(CombinedFormStats, {
      variables: {
        formIds: DashboardForms?.map((form) => form?.form_id),
        showTemplates: showTemplate,
      },
      skip: !DashboardForms === undefined || !DashboardForms?.length > 0,
    });
      const { data } = useQuery(GET_TEAM_SETTINGS, {
        fetchPolicy: "cache-and-network",
      });
  const isInitialized = useRef(false);

  const NavigateToURL = useNavigate();

  const count = CombinedFormStatsData?.getTeamStats?.total_forms;
  useEffect(() => {

    let forms = getFormsData?.forms;

    setDashboardForms(forms);
  }, [getFormsData?.forms]);

 const handleTemplate = () => {
  setShowTemplate((prev) => !prev);
};

  const [TerminateForm, { loading: TerminateFormLoading }] = useMutation(
    createTerminateForm,
    {
      onCompleted: (data) => {
        refetch();
      },
    }
  );
  const [defaultFeilds] = useMutation(getDefaultFeilds, {
    onCompleted: (data) => {
      refetch();
    },
  });


  const totalFormsLimit = formPlan?.FormLimit || 0;

  const formsPercentage =
    totalFormsLimit > 0 ? (count / totalFormsLimit) * 100 : 0;
  useEffect(() => {
    if (
      !isInitialized?.current &&
      Number(CombinedFormStatsData?.getTeamStats?.total_forms) === Number(0)
    ) {
      // console.log("initializing")
      isInitialized.current = true;
      TerminateForm().then(() => {});
      defaultFeilds();
    }
  }, [CombinedFormStatsData, TerminateForm, defaultFeilds]);
  const [isRefetching, setIsRefetching] = useState(false);

 

  const handleSearch = (search) => {
    setSearchQuery(search);
    setIsRefetching(true);
    debouncedSearch(search);
  };
  const debouncedSearch = useRef(
    debounce(async (value) => {
      await refetch({
        search: value,

      });
      setIsRefetching(false);
    }, 500)
  ).current;

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, []);
  const handlePageClick = (page) => {
    if (page < 1 || page > Math.ceil(countTotalFormsData?.countForms / Pagelimit)) {
      return;
    }
    setCurrentPage(page);
    fetchMore({
      variables: {
        offset: String((Number(page) - 1) * Pagelimit), // Convert to string
      },
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return {
          ...prev,
          forms: fetchMoreResult.forms,
        }
      }

    });

  };
  const renderPageNumbers = () => {
    const totalPages = Math.ceil(countTotalFormsData?.countForms / Pagelimit);
  
    const maxVisibleButtons = 5;
    const halfVisibleButtons = Math.floor(maxVisibleButtons / 2);
  
    let startPage = Math.max(1, currentPage - halfVisibleButtons);
    let endPage = Math.min(totalPages, currentPage + halfVisibleButtons);
  
    if (totalPages <= maxVisibleButtons) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= halfVisibleButtons) {
        endPage = maxVisibleButtons;
      } else if (currentPage + halfVisibleButtons >= totalPages) {
        startPage = totalPages - maxVisibleButtons + 1;
      }
    }
  
    const pageNumbers = Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);
  
    return (
      <div className="pagination-container">
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            {(currentPage !== 1 && totalPages > maxVisibleButtons) && (
              <li className="page-item pointer">
                <div className="page-link" aria-label="Previous" onClick={() => handlePageClick(currentPage - 1)}>
                  <span aria-hidden="true">&laquo;</span>
                  <span className="sr-only">Previous</span>
                </div>
              </li>
            )}
  
            {startPage > 1 && (
              <>
                <li className="page-item pointer" onClick={() => handlePageClick(1)}>
                  <div className="page-link">1</div>
                </li>
                {startPage > 2 && (
                  <li className="page-item">
                    <span className="page-link">...</span>
                  </li>
                )}
              </>
            )}
  
            {pageNumbers.map((pageNumber) => (
              <li
                className={`page-item pointer ${pageNumber === currentPage ? 'active' : ''}`}
                key={pageNumber}
                onClick={() => handlePageClick(pageNumber)}
              >
                <div className="page-link">{pageNumber}</div>
              </li>
            ))}
  
            {endPage < totalPages && (
              <>
                {endPage < totalPages - 1 && (
                  <li className="page-item">
                    <span className="page-link">...</span>
                  </li>
                )}
                <li className="page-item pointer" onClick={() => handlePageClick(totalPages)}>
                  <div className="page-link">{totalPages}</div>
                </li>
              </>
            )}
  
            {(currentPage !== totalPages && totalPages > maxVisibleButtons) && (
              <li className="page-item pointer">
                <div className="page-link" aria-label="Next" onClick={() => handlePageClick(currentPage + 1)}>
                  <span aria-hidden="true">&raquo;</span>
                  <span className="sr-only">Next</span>
                </div>
              </li>
            )}
          </ul>
        </nav>
      </div>
    );
  };


  useEffect(() => {
    if (duplicateFormData?.DuplicateForm) {
      refetch();
    }
  }, [duplicateFormData?.DuplicateForm, refetch]);

  if (deleteFormLoading || duplicateFormLoading || TerminateFormLoading) return <Loaderr />;
  if (getFormsLoading) return <Loaderr />;

  return (
    <div>
      <ToastContainer />
      <div className="main fmc-main p-0">
        <section
          className="container-lg container-fluid pt-5 pb-5 mb-5"
          style={{ minHeight: "700px" }}
        >
          <div
            className="d-lg-none alert alert-warning banner-top p-3 py-2 mb-3 rounded-0 m-0"
            role="alert"
          >
            <div className="d-flex flex-wrap align-items-center">
              <div className="d-flex align-items-center my-2">
                <i className="bx bx-mobile fs-5"></i>
                <i className="bx bx-arrow-to-right me-2 fs-5"></i>
                <i className="bx bx-desktop me-2 fs-5"></i>
              </div>

              <span className="my-2">
                Please switch to desktop and optimize this view. Smaller screens
                are not efficient.
              </span>
            </div>
          </div>

          <div className="d-flex flex-wrap justify-content-between">
            <h5>Forms and Integration</h5>
            <div className="left">
              {DashboardForms?.length >= formPlan?.FormLimit ? (
                <>
                  {" "}
                  <button
                    className="btn btn-primary float-end "
                    onClick={() => NavigateToURL("create-form")}
                    disabled={true}
                  >
                    Create New Form
                  </button>{" "}
                </>
              ) : (
                <button
                  className="btn btn-primary float-end"
                  onClick={() => NavigateToURL("create-form")}
                >
                  Create New Form
                </button>
              )}


                {
                  data?.GetTeamSettings[0].allow_to_manage_template === true && (
                    <button
                    className="btn btn-primary float-end me-3"
                    onClick={handleTemplate}
                  >
                    {showTemplate ? "View Forms" : "View Templates"}
                  </button>
                  )
                    
                }
               
            </div>
          </div>

          <div className="w-100">
            {DashboardForms?.length >= formPlan?.FormLimit ? (
              <div className="limit-alert w-100 my-3">
                <div className="limit-icon">
                  <i className="iconify" data-icon="dashicons:lock"></i>
                </div>
                You have reached the limit of {formPlan?.FormLimit} forms. To
                add more forms please upgrade your plan.
              </div>
            ) : (
              <span
                style={{ marginTop: "10px" }}
                className="text-secondary small mt-2"
              >
                Customise forms and Stages with your own fields or use
                predefined inputs. You can create upto {formPlan?.FormLimit}{" "}
                forms
              </span>
            )}
          </div>

          <div className="d-flex flex-wrap gap-4 py-3">
            <div className="fm-crd">
              <i className="iconify fs-1" data-icon="pepicons-pencil:file"></i>
              <div className="vr"></div>
              <b className="fs-2">
                {CombinedFormStatsData?.getTeamStats?.total_forms || 0}
              </b>
              <span>Out of {formPlan?.FormLimit || 0} forms</span>
            </div>

            <div className="fm-crd">
              <i className="iconify fs-1" data-icon="bi:input-cursor"></i>
              <div className="vr"></div>
              <b className="fs-2">
                {CombinedFormStatsData?.getTeamStats?.total_custom_fields || 0}
              </b>
              <span>Out of {formPlan?.CustomFieldLimit} Custom fields</span>
              {/* <i className="iconify text-danger fs-5" data-icon="mdi:warning" ></i> */}
            </div>

            <div className="fm-crd">
              <i className="iconify fs-1" data-icon="carbon:list"></i>
              <div className="vr"></div>
              <div className="d-flex flex-column gap-3">
                <span className="fs-6">
                  {formPlan?.EntryLimit || 0} DashboardForms entries per month
                </span>
                <div
                  className="progress w-100 rounded-pill mb-2"
                  style={{ height: "20px", position: "relative" }}
                >
                  <div
                    className="progress-bar bg-success"
                    role="progressbar"
                    style={{
                      width: `${isNaN(formsPercentage) ? 0 : formsPercentage}%`,
                    }}
                  >
                    <span
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        color: "black",
                        fontWeight: "bold",
                        fontSize: "12px", // Adjust font size if needed
                      }}
                    >
                      {isNaN(formsPercentage) ? 0 : Math.round(formsPercentage)}
                      %
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-8 col-xxl-5">
                           <label className="small">Search</label>
                           <input
                              className="form-control input-bn search-fgy mb-1"
                              placeholder="search forms"
                              onChange={(e) => {
                                handleSearch(e.target.value);
                              }}
                              value={searchQuery}                           />
                           </div>
       

          </div>

          {DashboardForms?.length > 0 && (
            <>
              <section
                className="d-flex flex-column py-4 gap-3"
                style={{ paddingBottom: "400px !important" }}
              >
                <div className="d-md-flex flex-wrap border rounded-3 d-none fm-list-container-thead">
                  <div className="d-flex flex-wrap col-12 col-md-6 align-items-center justify-content-between pb-2 pt-4 py-md-3 px-4 border-0 border-end border">
                    <b className="p-0 m-0">Forms</b>
                  </div>
                  <div className="d-flex align-items-center justify-content-center px-4 col col-md-2 border-0 border-end border">
                    <p className="p-0 m-0">Today</p>
                  </div>

                  <div className="d-flex align-items-center justify-content-center px-4 col col-md-2 border-0 border-end border">
                    <p className="p-0 m-0">Total Entries</p>
                  </div>
                  <div className="d-flex align-items-center justify-content-center px-4 col col-md-1 border-0 border-end border">
                    <p className="p-0 m-0">Status</p>
                  </div>
                  <div className="d-flex align-items-center justify-content-center px-4 col col-md-1"></div>
                </div>

                <section
                  className="d-flex flex-column py-4 gap-3"
                  style={{ paddingBottom: "800px !important" }}
                >
                  {isRefetching ? (
                    <div className="react-loader" style={{ fontSize: "16px", color: "blue", marginLeft: "50%" }}>
                    {Spinner}
                  </div>
                  ) : DashboardForms?.length > 0 &&
                    DashboardForms?.map((item) => {
                      const count =
                        CombinedFormStatsData?.getFormEntryCounts?.find(
                          (stat) =>
                            String(stat?.form_id) === String(item?.form_id)
                        );
                      //console.log("count", count)

                      return (
                        <FormDetails
                          deleteForm={deleteForm}
                          refetch={refetch}
                          Formlength={DashboardForms?.length}
                          key={item?.form_id}
                          totalcount={count?.total_count}
                          todayCount={count?.today_count}
                          form_type={item?.form_type}
                          formId={item?.form_id}
                          base_form_template={item?.base_form_template}
                          formName={item?.form_name}
                          teamId={item?.team_id}
                          leadtype={item?.lead_form_type}
                          setDashboardForms={setDashboardForms}
                          DashboardForms={DashboardForms}
                          FormStatusInfo={item?.status}
                          duplicate={duplicate}
                          setDuplicate={setDuplicate}
                          duplicateFormMutation={duplicateFormMutation}
                          showTemplate={showTemplate}
                        />
                      );
                    })}
                </section>
                
                    {renderPageNumbers()}
              
              </section>
            </>
          )}
          {
            DashboardForms?.length === 0 && (
              <div className="d-flex justify-content-center">
                <div className=" d-flex alert alert-warning w-100 justify-content-center" role="alert">
                  search not found
                </div>
              </div>
            )
          }
          {DashboardForms?.length === undefined ? (
            <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <></>
          )}
        </section>
        <div className="my-5 py-5"></div>
      </div>
    </div>
  );
};
export default FormList;
