import React, { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { Spin } from "antd";
import CreateApptAddress from "./CreateApptAddress";
import ApptAddress from "./ApptAddress";

const GetApptAddresses = gql`
  query GetApptAddresses {
    GetApptAddresses {
      address_type
      address
      team_id
    }
  }
`;

export const ApptAddressesList = () => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const { loading, data, refetch } = useQuery(GetApptAddresses);

  const handleCloseModal = () => {
    setShowCreateModal(false);
  };

  if (loading)
    return (
      <div className="text-center gy-4 mt-5">
        <Spin size="large" />
      </div>
    );

  return (
    <>
      <div>
        <div className="d-flex align-items-center justify-content-between mb-2">
          <div className="d-flex flex-column">
            <p className="small text-secondary">
              Manage and edit appointment addresses
            </p>
          </div>
          <button
            className="btn btn-primary"
            onClick={() => setShowCreateModal(true)}
          >
            New Appointment Address
          </button>
        </div>
        <div className="row row-cols-2 gy-4">
          {data?.GetApptAddresses?.map((address) => (
            <ApptAddress
              key={address.address_type}
              address={address}
              refetch={refetch}
            />
          ))}
        </div>
      </div>

      <CreateApptAddress
        showModal={showCreateModal}
        setShowModal={setShowCreateModal}
        handleCloseModal={handleCloseModal}
        refetch={refetch}
      />
    </>
  );
};
