import React, { useContext } from "react";
import Filter from "../../../automations/Filter";
import SlackNotification from "../../../automations/SlackNotification";
import FubAutomation from "../../../automations/FubAutomation";
import SisuActivity from "../../../automations/SisuActivity";
import AwsRedisDatabase from "../../../automations/AwsRedisDatabase";
import EmailNotification from "../../../automations/EmailNotification";
import { WorkFlowSessionInfo } from "../../../pages/automation-builder/workflows/EditWorkflow";
import ArrPng from "../../../assets/images/arr.png";
import Utilities from "../../../automations/Utilities";
import WebhookToZapier from "../../../automations/WebhookToZapier";
import WebhookToOtc from "../../../automations/WebhookToOtc";


const SelectAutomation = ({ action_id,action_platform,setSelected_action, prev_action_id}) => {
  const { workflow_helper } = useContext(WorkFlowSessionInfo);
  const uuid = Math.floor(Math.random() * 1000000000);
  const handleClick = () => {
  
  if(action_platform==="unknown"){
    workflow_helper.current.change_action_id(action_id,"FUB"+uuid,{ platform: "FUB","api_name": "", "api_type": "", "data_mapper": {}}, )
  }else{
  workflow_helper.current.add_action({"action_id": "FUB"+uuid, platform: "FUB","api_name": "", "api_type": "", "data_mapper": {}}, prev_action_id)
  }
      
  }

  const handelFilterClick = () => {
    if(action_id){
      
      workflow_helper.current.change_action_id(action_id,"AdvanceFilter"+uuid,{platform: "FILTER","api_name": "", "api_type": "", "data_mapper": {}})
    }else{
    workflow_helper.current.add_action({"action_id": "AdvanceFilter"+uuid, platform: "FILTER","api_name": "", "api_type": "", "data_mapper": {}}, prev_action_id)
    }
  }

  const createUtilityAction = () => {
    if(action_id){
      workflow_helper.current.change_action_id(action_id,"Utility"+uuid,{platform: "Utility","api_name": "", "api_type": "", "automation_name": "", "data_mapper": {"operator": "", "days":"", "hours": "", "minutes": ""}})
    }else{
    workflow_helper.current.add_action({"action_id": "Utility"+uuid, platform: "Utility", "api_name": "", "api_type": "", "data_mapper": {}}, prev_action_id)
    }
  }

  const createWebhookToZapier = () => {
    if(action_id){
      workflow_helper.current.change_action_id(action_id,"Zapier"+uuid,{platform: "Zapier","api_name": "", "api_type": "", "automation_name": "Webhook", "data_mapper": {}})
    }else{
    workflow_helper.current.add_action({"action_id": "Zapier"+uuid, platform: "Zapier","api_name": "", "api_type": "", "automation_name": "Webhook", "data_mapper": {}}, prev_action_id)
    }
  }

  const createWebhookToOtc = () => {
    if(action_id){
      workflow_helper.current.change_action_id(action_id,"OTC"+uuid,{platform: "OTC","api_name": "", "api_type": "", "automation_name": "Webhook", "data_mapper": {}})
    }else{
    workflow_helper.current.add_action({"action_id": "OTC"+uuid, platform: "OTC","api_name": "", "api_type": "", "automation_name": "Webhook", "data_mapper": {}}, prev_action_id)
    }
  }

  return (
    <>
      <div className="d-flex justify-content-center" >
        <img height="70" src={ArrPng} alt="" />
      </div>
      <div className="border rounded-3 p-4 mb-2" onClick={()=>setSelected_action(null)}>
        <p className="w-100">Select an automation</p>
          <div className="rounded-3 row row-cols-2 gx-2">
            <div
              className=" mb-4 pointer"
              onClick={()=>{handleClick()}}
            >
              <FubAutomation />
            </div>
            <div
              className=" mb-4 pointer"
              onClick={()=>{handelFilterClick()
              }
              }
            >
              <Filter />
            </div>
            <div
              className=" mb-4 pointer"
              onClick={()=>{createUtilityAction()
              }
              }
            >
              <Utilities />
            </div>
            <div className="mb-4 pointer"
              onClick={()=>{createWebhookToZapier()}}
            >
              <WebhookToZapier />
            </div>
            <div className=" mb-4 pointer"
              onClick={()=>{createWebhookToOtc()}}
            > 
              <WebhookToOtc />
            </div>
            <div
              className=" mb-4 pointer"
            >
              <AwsRedisDatabase />
            </div>
            <div
              className=" mb-4 pointer"
            >
              <EmailNotification />
            </div>
            <div
              className=" mb-4 pointer"
            >
              <SisuActivity />
            </div>
            <div
              className=" mb-4 pointer"
            >
              <SlackNotification />
            </div>
          </div>
        {/* // )} */}
      </div>
    </>
  );
};

export default SelectAutomation;
