import React, { useState, useEffect, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom"; // For navigation
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS
import "ag-grid-community/styles/ag-theme-alpine.css"; // Theme CSS
import { gql, useQuery } from "@apollo/client";
import { Spin } from "antd";
import { useParams } from "react-router-dom";

const GetFieldsByTeamId = gql`
  query GetFieldsByTeamId($teamId: String) {
    GetFieldsByTeamId(team_id: $teamId) {
      field_id
      field_name
      field_type
      input_type
      settings
      field_sub_type
      opportunity_field_id
    }
  }
`;

const FormEntryAGridTable = ({ entries, pageSize = 10, teamid,formType }) => {
  const navigate = useNavigate(); // React Router navigation hook
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [visibleColumns, setVisibleColumns] = useState({}); // Tracks visibility state for each column
  const gridRef = useRef(null); // Create a ref for the grid
  const paramsid = useParams();

  const { data } = useQuery(GetFieldsByTeamId, {
    variables: { teamId: teamid },
  });

  // Memoize the field map for faster lookups
  const fieldMap = useMemo(() => {
    return (
      data?.GetFieldsByTeamId.reduce((map, field) => {
        map[field.field_id] = field?.field_name || field.settings?.label || field.field_id;
        return map;
      }, {}) || {}
    );
  }, [data]);

  const defaultColDef = {
    flex: 1,
    minWidth: 100,
    sortable: true,
    filter: true,
    floatingFilter: true,
    resizable: true,
    autoHeight: false, // Ensures rows have a fixed height
  };

  function CustomGroupCellRenderer(params) {
    return params.value;
  }

  useEffect(() => {
    if (!entries || entries.length === 0 || !data?.GetFieldsByTeamId) return;

    const allKeys = new Set();

    entries.forEach((entry) => {
      Object.keys(entry).forEach((key) => {
        if (key !== "__typename" && key !== "form_id" && key !== "form_entry_id") {
          allKeys.add(key);
        }
      });

      if (entry.form_entry_data) {
        Object.keys(entry.form_entry_data).forEach((nestedKey) =>
          allKeys.add(`form_entry_data.${nestedKey}`)
        );
      }
    });

    const columnDefs = Array.from(allKeys).map((key) => {
      if (key === "entry_created_date") {
        return {
          field: 'entry_created_date',
          headerName: "Created Date",
          filter: "agDateColumnFilter", // Date filter for this column
          sortable: true,
          valueGetter: (params) => {
            return params?.data['entry_created_date'] 
              ? new Date(parseInt(params?.data['entry_created_date'], 10)) 
              : null;
          },
          valueFormatter: (params) => {
            if (params?.value) {
              const formattedDate = new Date(params.value);
              return formattedDate?.toLocaleString("en-US", {
                weekday: "short", // E.g., Mon
                year: "numeric",  // E.g., 2025
                month: "short",   // E.g., Dec
                day: "numeric",   // E.g., 25
                hour: "numeric",  // E.g., 10
                minute: "numeric", // E.g., 30
              });
            }
            return "-"; // Fallback for empty/null values
          },
          filterParams: {
            comparator: (filterDate, cellValue) => {
              if (!cellValue) return -1;
    
              // Extract the year and month for both the filter date and the cell value
              const filterYearMonth = new Date(filterDate);
              const cellYearMonth = new Date(cellValue);
              const cellDate = new Date(cellValue);
    
              // Set day and time to ensure comparison is only based on year and month
              filterYearMonth?.setDate(1);
              filterYearMonth?.setHours(0, 0, 0, 0);
    
              cellYearMonth?.setDate(1);
              cellYearMonth?.setHours(0, 0, 0, 0);
    
              // Compare only the year and month part
              if (filterYearMonth?.getFullYear() === cellYearMonth?.getFullYear() &&
                  filterYearMonth?.getMonth() === cellYearMonth?.getMonth() &&
                  filterDate.getDate() === cellDate.getDate()) {
                return 0; // Match found
              }
    
              return cellDate < filterDate ? -1 : 1; // No match
            },
            browserDatePicker: true, // Enable native browser date picker
            debounceMs: 300, // Delay filter execution for better UX
          },
        };
      }
    
      // Handling other date-related columns (excluding entry_created_date)
      if (key.startsWith("Date-") || key.includes("date")) {
        return {
          field: key,
          headerName: fieldMap[key] || key,
          filter: "agDateColumnFilter",
          sortable: true,
          valueGetter: (params) => {
            const rawValue = params?.data[key];
    
            if (!rawValue) return null;
    
            // If the value contains "t=date", extract the timestamp
            if (typeof rawValue === "string" && rawValue.includes("t=date")) {
              const timestamp = rawValue.replace(/\D/g, ""); // Extract numbers
              return new Date(parseInt(timestamp, 10));
            }
    
            // If the value is already a timestamp (number or string)
            if (!isNaN(rawValue) && rawValue.length >= 10) {
              return new Date(parseInt(rawValue, 10));
            }
    
            // Check if value is in "DD/MM/YYYY" format
            const dateParts = rawValue.split("/");
            if (dateParts.length === 3) {
              const [day, month, year] = dateParts.map(Number);
              return new Date(year, month - 1, day);
            }
    
            return null; // Default if the format isn't recognized
          },
          valueFormatter: (params) => {
            if (params?.value) {
              return new Date(params.value).toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              });
            }
            return "-";
          },
          filterParams: {
            comparator: (filterDate, cellValue) => {
              if (!cellValue) return -1;
              const cellDate = new Date(cellValue);
              return cellDate.toDateString() === new Date(filterDate).toDateString() ? 0 : cellDate < filterDate ? -1 : 1;
            },
            browserDatePicker: true,
            debounceMs: 300,
          },
        };
      }
    
      return {
        field: key,
        headerName: fieldMap[key] || key,
        filter: "agTextColumnFilter",
        cellRenderer: (params) =>
          params.value !== undefined && params.value !== null ? params.value : "-",
      };
    });
    

    const specialColumns = [
      {
        headerName: "Entry Number",
        valueGetter: "node.rowIndex + 1",
        sortable: false,
        filter: false,
      },
      columnDefs.find((col) => col.field === "entry_created_date"),
      formType !== "Lead Forms" && {
        field: "InputField-opp-key",
        headerName: "Opp Key",
        cellRenderer: (params) => {
          const isValuePresent = params.value && params.value !== "-";
          if (isValuePresent) {
            return (
              <button
                onClick={() => navigate(`/forms/opportunities/${params.value}?id=${paramsid.id}`)}
                style={{
                  border: "none",
                  backgroundColor: "transparent",
                  color: "blue",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
              >
                View Opportunities
              </button>
            );
          } else {
            return <span style={{ display: "flex", justifyContent: "center" }}>-</span>;
          }
        },
        sortable: false,
        filter: false,
      },
      {
        headerName: "Form Entries",
        field: "form_entry_action",
        cellRenderer: (params) => (
          <button
            onClick={() =>
              navigate(`/forms/${params.data.form_id}/entries/${params.data.form_entry_id}`)
            }
            style={{
              border: "none",
              backgroundColor: "transparent",
              color: "blue",
              cursor: "pointer",
              textDecoration: "underline",
            }}
          >
            View Entry
          </button>
        ),
        sortable: false,
        filter: false,
      },
      columnDefs.find((col) => col.field === "InputField-Follow-up-boss-lead-id"),
    ].filter(Boolean); // Remove falsy values
    

    const otherColumns = columnDefs.filter(
      (col) =>
        !["entry_created_date", "InputField-opp-key","form_entry_action", "InputField-Follow-up-boss-lead-id"].includes(
          col.field
        )
    );

    const finalColumnDefs = [
      ...specialColumns,
      ...otherColumns,
    ];

    const visibilityState = finalColumnDefs.reduce((acc, col) => {
      acc[col.field] = true; // Default to visible
      return acc;
    }, {});

    const mappedRows = entries.map((entry) => {
      const flatEntry = { ...entry };

      if (entry.form_entry_data) {
        Object.keys(entry.form_entry_data).forEach((nestedKey) => {
          flatEntry[`form_entry_data.${nestedKey}`] = entry.form_entry_data[nestedKey];
        });
      }

      finalColumnDefs.forEach((col) => {
        const key = col.field;
        if (key && (flatEntry[key] === undefined || flatEntry[key] === null)) {
          flatEntry[key] = "-";
        }
      });

      return flatEntry;
    });

    setVisibleColumns(visibilityState);
    setColumnDefs(finalColumnDefs);
    setRowData(mappedRows);
  }, [entries, fieldMap, navigate]);

  const sideBar = {
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
      },
    ],
  };

  const filteredColumnDefs = columnDefs.filter((col) => visibleColumns[col.field]);

  const exportToCsv = () => {
    if (gridRef.current) {
      gridRef.current.api.exportDataAsCsv();
    }
  };

  return (
    <div className="ag-grid-container">
      <div className="d-flex mb-3">
        <button
          className="btn btn-primary d-flex align-items-center"
          onClick={exportToCsv}
          style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        >
          <i
            className="bx bx-download"
            style={{
              fontSize: "1rem",
              marginRight: "0.5rem",
              lineHeight: "1",
            }}
          ></i>
          Export to CSV
        </button>
      </div>

      {rowData.length === 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50%",
            paddingTop: "20px",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <div className="ag-theme-alpine" style={{ height: 600, width: "100%" }}>
          <AgGridReact
            ref={gridRef} // Attach the ref to the grid
            rowData={rowData}
            columnDefs={filteredColumnDefs}
            pagination={true}
            paginationPageSize={pageSize}
            defaultColDef={defaultColDef}
            sideBar={sideBar}
            frameworkComponents={{
              agGroupCellRenderer: CustomGroupCellRenderer,
            }}
            rowBuffer={20}
            domLayout="normal"
            animateRows={true}
            rowHeight={40}
          />
        </div>
      )}
    </div>
  );
};

export default FormEntryAGridTable;