import DOMPurify from "dompurify";

/**
 * Sanitizes the provided HTML string and optionally wraps plain text.
 * @param {string} htmlString - The HTML string to sanitize.
 * @param {string} plainTextClass - Optional class for wrapping plain text.
 * @returns {JSX.Element} - JSX rendering the sanitized HTML.
 */
export const sanitizeAndRenderLabel = (htmlString, plainTextClass = "") => {
  if (!htmlString) return null;

  const sanitizedLabel = DOMPurify.sanitize(htmlString, {
    ADD_ATTR: ["target", "rel"], // Allow target and rel attributes
  }).replace(
    /<a /g,
    '<a target="_blank" rel="noopener noreferrer" style="color: blue; text-decoration: underline;" '
  );

  const plainText = DOMPurify.sanitize(htmlString, { ALLOWED_TAGS: [] });
  const hasHyperlink = /<a\s+href=.*?>.*?<\/a>/.test(htmlString);

  return hasHyperlink ? (
    <div
      dangerouslySetInnerHTML={{
        __html: sanitizedLabel.replace(
          plainText,
          `<span class="${plainTextClass}">${plainText}</span>`
        ),
      }}
    />
  ) : (
    <h6>{plainText}</h6>
  );
};
