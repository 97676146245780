import { gql, useMutation, useQuery } from "@apollo/client";
import { type } from "jquery";
import {useNavigate } from "react-router-dom";

function useFormApiHelper() {
  // function GetCustomFields() {
  //   const GetCustomFields = gql`
  //     query GetCustomFields {
  //       GetCustomFields {
  //         field_id
  //         field_name
  //         field_description
  //         field_type
  //         settings
  //         linked_to
  //       }
  //     }
  //   `;
  //   const {
  //     loading: customFieldsLoading,
  //     error: customFieldsError,
  //     data: customFields,
  //   } = useQuery(GetCustomFields, {
  //     onError: (error) => {
  //       //////console.log("error", error);
  //     },
  //   });
  //   let hey = "hey";
  //   return { customFieldsLoading, customFieldsError, customFields, hey };
  // }

  function GetFormData(params) {
    const FormData = gql`
      query FormData($formId: String) {
  formData(form_id: $formId) {
    form_id
    team_id
    form_name
    form_description
    form_fields
    form_conditions
    status
    form_created_date
    form_updated_date
    update_by
    base_form_template
    color_scheme
    logo_url
    form_type
    pipeline
    stage
    is_this_appt_form
    disp_text
    select_disp_form
    disp_sched_hour
    disp_sched_minutes
    disp_reminder_hour
    disp_reminder_minutes
    make_reminder_rec
    lead_form_redirect_setting
    lead_form_redirect_url
    redirect_page_timeout
    thank_you_page_message
    lead_custom_field_name
    populate_link_for_new_leads
    backfill_link_for_existing_leads
    form_redirection_conditions_setting
    lead_form_type
    update_lead_tag
    deal_stage_prevention_setting
     invite_sent_to_client,
      wufoo_isa_calendar_invite,
      add_fub_relationships,
      relationship_calendar_invite
      is_form_template
      default_template_visibility
      select_appt_form
      show_form_logo

  }
}
    `;
    const {
      loading: formDataLoading,
      error: formDataError,
      data: formData,
    } = useQuery(FormData, {
      variables: { formId: params.id },
      onCompleted: (data) => {
        ////console.log("data>>", data);
      },
    });
    //////console.log("GetFormData", formData);
    return { formDataLoading, formDataError, formData };
  }

  function CreateFormEntry() {
    const CreateFormEntry = gql`
      mutation CreateFormEntry($formId: String, $formEntryData: GraphQLJSON) {
        CreateFormEntry(form_id: $formId, form_entry_data: $formEntryData) {
          form_id
          form_entry_id
          entry_created_date
        }
      }
    `;
    const [
      createFormEntry,
      {
        loading: createFormEntryLoading,
        error: createFormEntryError,
        data: createFormEntryData,
      },

    ] = useMutation(CreateFormEntry, {
      onCompleted: (data) => {
        ////console.log("data>", data);
      },
    });
    ////console.log("data>",createFormEntryError)
    return {
      createFormEntry,
      createFormEntryLoading,
      createFormEntryError,
      createFormEntryData,
    };
  }
  function GetFormEntries(params,formId) {
    const id=params?.id || formId
    const FormEntries = gql`
      query FormEntries($formId: String) {
        formEntries(form_id: $formId) {
          form_entry_id
          form_id
          form_entry_data
          entry_created_date
        }
      }
    `;
    const {
      loading: formEntriesLoading,
      error: formEntriesError,
      data: formEntries,
    } = useQuery(FormEntries, {
      variables: { formId: id  },
      skip: id === undefined || id === null,
    });
    //////console.log("FormEntries", formEntries);
    return { formEntriesLoading, formEntriesError, formEntries };
  }
  function FormEntry(params) {
    const FormEntry = gql`
      query FormEntry($formEntryId: String) {
        formEntry(form_entry_id: $formEntryId) {
          form_entry_id
          form_id
          form_entry_data
          entry_created_date
        }
      }
    `;
    const {
      loading: formEntryLoading,
      error: formEntryError,
      data: formEntry,
    } = useQuery(FormEntry, {
      variables: { formEntryId: params.entryid },
    });
    //////console.log("FormEntry", formEntry);
    return { formEntryLoading, formEntryError, formEntry };
  }
  function UpdateForm() {
    const UpdateForm = gql`
      mutation UpdateForm($formId: String, $formData: GraphQLJSON) {
        UpdateForm(form_id: $formId, form_data: $formData) {
          team_id
          form_id
        }
      }
    `;
    const [
      updateForm,
      {
        loading: updateFormLoading,
        error: updateFormError,
        data: updateFormData,
      },
    ] = useMutation(UpdateForm, {
      onCompleted: (data) => {},
    });
    return { updateForm, updateFormLoading, updateFormError, updateFormData };
  }

  function UpdateFormSettings() {
    const UpdateFormSettings = gql`
    mutation UpdateFormSettings($isThisApptForm: String, $status: Boolean, $stage: String, $pipeline: String, $formDescription: String, $formId: String) {
  UpdateFormSettings(is_this_appt_form: $isThisApptForm, status: $status, stage: $stage, pipeline: $pipeline, form_description: $formDescription, form_id: $formId) {
    form_id
    team_id
  }
}`
    const [
      updateFormSetting, {
        loading: updateFormSettingsLoading,
        error: updateFormSettingsError,
        data: updateFormSettingsData,
      }] = useMutation(UpdateFormSettings, {
        onCompleted: (data) => {
          ////console.log("data", data);
        },
      });
    return { updateFormSetting, updateFormSettingsLoading, updateFormSettingsError, updateFormSettingsData };
  }


  function DeleteFormEntry() {
    const DeleteFormEntry = gql`
      mutation DeleteFormEntry($formEntryId: String, $formId: String) {
        DeleteFormEntry(form_entry_id: $formEntryId, form_id: $formId) {
          form_id
          form_entry_id
          entry_created_date
        }
      }
    `;
    const [
      deleteFormEntry,
      {
        loading: deleteFormEntryLoading,
        error: deleteFormEntryError,
        data: deleteFormEntryData,
      },
    ] = useMutation(DeleteFormEntry, {
      onCompleted: (data) => {
        //////console.log("data", data);
      },
    });
    return {
      deleteFormEntry,
      deleteFormEntryLoading,
      deleteFormEntryError,
      deleteFormEntryData,
    };
  }
  function UpdateFormEntry() {
    const UpdateFormEntry = gql`
      mutation UpdateFormEntry(
        $formId: String
        $formEntryId: String
        $formEntryData: GraphQLJSON
      ) {
        UpdateFormEntry(
          form_id: $formId
          form_entry_id: $formEntryId
          form_entry_data: $formEntryData
        ) {
          form_id
          form_entry_id
          entry_created_date
        }
      }
    `;
    const [
      updateFormEntry,
      {
        loading: updateFormEntryLoading,
        error: updateFormEntryError,
        data: updateFormEntryData,
      },
    ] = useMutation(UpdateFormEntry, {
      onCompleted: (data) => {
        //////console.log("data", data);
      },
    });
    return {
      updateFormEntry,
      updateFormEntryLoading,
      updateFormEntryError,
      updateFormEntryData,
    };
  }
  function Opps(stage,type,customCommision) {
    const tempStage=stage || "" 
    const tempType=type || ""
    const tempCustComm=customCommision || ""
    ////console.log("tempStage",tempStage,"tempType",tempType,"tempCust",customCommision);
    const Opps = gql`
      query Opps {
        Opps {
          opp_key
          sisu_client_updated_ts
          opp_updated_ts
          opp_stage
          opp_agreement_signed_date
          fub_deal_created_ts
          opp_type
          opp_appt_date
          fub_deal_stage_name
          opp_appt_met_date
          fub_person_id
          sisu_client_created_ts
          opp_created_ts
          fub_deal_id
          opp_appt_disposition
          teamFubDealId
          team
          fub_deal_entered_stage_ts
          sisu_client_id
          opp_assigned_osa
          opp_isa
          opp_notes
          opp_address
          opp_agreement_expiration_date
          appt_set_entry_id
          fub_appt_start_time
          fub_original_appt_start_time
          disp_text_wait_timestamp
          appt_set_lead_type
          appt_set_platform
          disp_text_original_wait_timestamp
          opp_address2
          opp_city
          opp_postal_code
          opp_last_name
          opp_state
          previous_opp_stage
          CreateEntryId
          pipeline_entry_id
          opp_forecasted_close_date
          opp_under_contract_date
          appt_form_id
          form_id_entry_id
          opp_settlement_date
          CreateFormId
          appt_outcome
          external_system_key_buyer
          otc_property_id
          external_system_key_seller
          FormId_EntryIds
          opp_price
          opp_commission_percent
          fub_appt_id
          custom_fields
          opp_custom_fields
        }
      }
    `;
    const {
      loading: oppsLoading,
      error: oppsError,
      data: opps,
    } = useQuery(Opps, {
      variables:{
        filters: {
          opp_stage: tempStage,
          opp_type: tempType,
          opp_custom_fields: tempCustComm,
        }
      },
      onCompleted: (data) => {
        ////console.log("data", data);
      },
    });
    return { oppsLoading, oppsError, opps };
  }
  function Opps(filters) {
    const Opps = gql`
      query Opps($filters: OppFilterInput) {
        Opps(filter: $filters) {
          opp_key
          sisu_client_updated_ts
          opp_updated_ts
          opp_stage
          opp_agreement_signed_date
          fub_deal_created_ts
          opp_type
          opp_appt_date
          fub_deal_stage_name
          opp_appt_met_date
          fub_person_id
          sisu_client_created_ts
          opp_created_ts
          fub_deal_id
          opp_appt_disposition
          teamFubDealId
          team
          fub_deal_entered_stage_ts
          sisu_client_id
          opp_assigned_osa
          opp_isa
          opp_notes
          opp_address
          opp_agreement_expiration_date
          appt_set_entry_id
          fub_appt_start_time
          fub_original_appt_start_time
          disp_text_wait_timestamp
          appt_set_lead_type
          appt_set_platform
          disp_text_original_wait_timestamp
          opp_address2
          opp_city
          opp_postal_code
          opp_last_name
          opp_state
          previous_opp_stage
          CreateEntryId
          pipeline_entry_id
          opp_forecasted_close_date
          opp_under_contract_date
          appt_form_id
          form_id_entry_id
          opp_settlement_date
          CreateFormId
          appt_outcome
          external_system_key_buyer
          otc_property_id
          external_system_key_seller
          FormId_EntryIds
          opp_price
          opp_commission_percent
          fub_appt_id
          custom_fields
          opp_custom_fields
        }
      }
    `;
    const {
      loading: oppsLoading,
      error: oppsError,
      data: opps,
    } = useQuery(Opps, {

      onCompleted: (data) => {
        ////console.log("data", data);
      },
    });
    return { oppsLoading, oppsError, opps };
  }
  function GetOppByOppId(oppKey) {
    const Opportunity = gql`
      query Opportunity($oppKey: String) {
        opportunity(opp_key: $oppKey) {
          opp_key
          sisu_client_updated_ts
          opp_updated_ts
          opp_stage
          opp_agreement_signed_date
          fub_deal_created_ts
          opp_type
          opp_appt_date
          fub_deal_stage_name
          opp_appt_met_date
          fub_person_id
          sisu_client_created_ts
          opp_created_ts
          fub_deal_id
          opp_appt_disposition
          teamFubDealId
          team
          fub_deal_entered_stage_ts
          sisu_client_id
          opp_assigned_osa
          opp_isa
          opp_notes
          opp_address
          opp_agreement_expiration_date
          appt_set_entry_id
          fub_appt_start_time
          fub_original_appt_start_time
          disp_text_wait_timestamp
          appt_set_lead_type
          appt_set_platform
          disp_text_original_wait_timestamp
          opp_address2
          opp_city
          opp_postal_code
          opp_last_name
          opp_state
          previous_opp_stage
          CreateEntryId
          pipeline_entry_id
          opp_forecasted_close_date
          opp_under_contract_date
          appt_form_id
          form_id_entry_id
          opp_settlement_date
          CreateFormId
          appt_outcome
          external_system_key_buyer
          otc_property_id
          external_system_key_seller
          FormId_EntryIds
          opp_price
          opp_commission_percent
          fub_appt_id
          custom_fields
        }
      }
    `;
    const { loading, error, data } = useQuery(Opportunity, {
      variables: { oppKey: oppKey },
    });
    return { loading, error, data };
  }

  function UpdateOpp() {
    const UpdateOpp = gql`mutation UpdateOpp($oppKey: String, $oppData: GraphQLJSON) {
    UpdateOpp(opp_key: $oppKey, opp_data: $oppData) {
      opp_key
      sisu_client_updated_ts
      opp_updated_ts
      opp_stage
      opp_agreement_signed_date
      fub_deal_created_ts
      opp_type
      opp_appt_date
      fub_deal_stage_name
      opp_appt_met_date
      fub_person_id
      sisu_client_created_ts
      opp_created_ts
      fub_deal_id
      opp_appt_disposition
      teamFubDealId
      team
      fub_deal_entered_stage_ts
      sisu_client_id
      opp_assigned_osa
      opp_isa
      opp_notes
      opp_address
      opp_agreement_expiration_date
      appt_set_entry_id
      fub_appt_start_time
      fub_original_appt_start_time
      disp_text_wait_timestamp
      appt_set_lead_type
      appt_set_platform
      disp_text_original_wait_timestamp
      opp_address2
      opp_city
      opp_postal_code
      opp_last_name
      opp_state
      previous_opp_stage
      CreateEntryId
      pipeline_entry_id
      opp_forecasted_close_date
      opp_under_contract_date
      appt_form_id
      form_id_entry_id
      opp_settlement_date
      CreateFormId
      appt_outcome
      external_system_key_buyer
      otc_property_id
      external_system_key_seller
      FormId_EntryIds
      opp_price
      opp_commission_percent
      fub_appt_id
      custom_fields

    }
  }`;
    const [
      updateOpp,
      { loading: updateOppLoading, error: updateOppError, data: updateOppData },
    ] = useMutation(UpdateOpp, {
      onCompleted: (data) => {
        ////console.log("data", data);
      },
    });
    return { updateOpp, updateOppLoading, updateOppError, updateOppData };
  }

  function DuplicateForm(){
  const duplicateForm = gql`
  mutation DuplicateForm($formId: String) {
  DuplicateForm(form_id: $formId) {
    form_id
    team_id
    form_name
    form_description
    form_fields
    form_conditions
    status
    form_created_date
    form_updated_date
    update_by
    base_form_template
    color_scheme
    logo_url
    form_type
    pipeline
    stage
    is_this_appt_form
    disp_text
    select_disp_form
    disp_sched_hour
    disp_sched_minutes
    disp_reminder_hour
    disp_reminder_minutes
    make_reminder_rec
    lead_form_redirect_setting
    thank_you_page_message
    redirect_page_timeout
    lead_form_redirect_url
  }
}
`;
  const [
    duplicateFormMutation,
    {
      loading: duplicateFormLoading,
      error: duplicateFormError,
      data: duplicateFormData,
    },
  ] = useMutation(duplicateForm, {
    onCompleted: (data) => {
      //////console.log("data", data);
    },

  });
return {duplicateFormMutation, duplicateFormLoading, duplicateFormError, duplicateFormData}
}

function UpdateFormName(){
  const UpdateForm = gql`
  mutation UpdateFormName($formId: String, $formName: String) {
    UpdateFormName(form_id: $formId, form_name: $formName) {
      team_id
      form_id
    }
  }
`;
const [
  UpdateFormData,
  {
    loading: UpdateNameLoading,
    error: UpdateNameError,
    data: UpdateNameData,
  },
] = useMutation(UpdateForm, {});

return {UpdateFormData, UpdateNameLoading, UpdateNameError, UpdateNameData}
}

function CreateFieldFuntion(){
  const createField= gql`
mutation CreateField($fieldData: GraphQLJSON) {
  CreateField(field_data: $fieldData) {
    field_id
  }
}`
const [createFieldMutation, { data: createFieldData, loading: createFieldLoading, error: createFieldError }] = useMutation(createField, {
  onCompleted: (data) => {
    // ////console.log("createFieldMutation", data);
  },
});
return {createFieldMutation, createFieldData, createFieldLoading, createFieldError}
}

function GetFormFields(){
  const FormFields=gql`
query GetFieldsByTeamId {
  GetFieldsByTeamId {
    field_id
    field_name
    field_type
    input_type
    settings
    field_sub_type
    opportunity_field_id
  }
}`
const {loading:loadingFields,error:error1,data:FormFieldsData,refetch}=useQuery(FormFields,{ 
})
return {FormFieldsData,loadingFields,error1,refetch}
}

function GetFieldMappingFields(){
  const FormFields=gql`
query GetFieldMappingFieldsByTeamId {
  GetFieldMappingFieldsByTeamId {
    field_id
    field_name
    field_type
    input_type
    settings
    field_sub_type
    opportunity_field_id
  }
}`
const {loading:loadingFields,error:error1,data:FormFieldsData,refetch}=useQuery(FormFields,{ 
})
return {FormFieldsData,loadingFields,error1,refetch}
}

function GetOppData(OppKey){
  const opportunityData = gql`
  query Opportunity($oppKey: String) {
    opportunity(opp_key: $oppKey) {
      opp_key
      sisu_client_updated_ts
      opp_updated_ts
      opp_stage
      opp_agreement_signed_date
      fub_deal_created_ts
      opp_type
      opp_appt_date
      fub_deal_stage_name
      opp_appt_met_date
      fub_person_id
      sisu_client_created_ts
      opp_created_ts
      fub_deal_id
      opp_appt_disposition
      teamFubDealId
      team
      fub_deal_entered_stage_ts
      sisu_client_id
      opp_assigned_osa
      opp_isa
      opp_notes
      opp_address
      opp_agreement_expiration_date
      appt_set_entry_id
      fub_appt_start_time
      fub_original_appt_start_time
      disp_text_wait_timestamp
      appt_set_lead_type
      appt_set_platform
      disp_text_original_wait_timestamp
      opp_address2
      opp_city
      opp_postal_code
      opp_last_name
      opp_state
      previous_opp_stage
      CreateEntryId
      pipeline_entry_id
      opp_forecasted_close_date
      opp_under_contract_date
      appt_form_id
      form_id_entry_id
      opp_settlement_date
      CreateFormId
      appt_outcome
      external_system_key_buyer
      otc_property_id
      external_system_key_seller
      FormId_EntryIds
      opp_price
      opp_commission_percent
      fub_appt_id
      form_entries {
        form_entry_id
        form_id
        form_entry_data
        entry_created_date
      }
    }
  }
`;
const {
  loading: oppLoading,
  error: oppsError,
  data: oppData,
} = useQuery(opportunityData, {
  variables: {
    oppKey: OppKey,
  },
});
return {oppLoading, oppsError, oppData}
}

function CreateOpp(signature, paramsContext){
  ////console.log("signature", signature,"Val",paramsContext);
  const navigate = useNavigate();
  const CreateOpportunity = gql`
mutation CreateOpp($form_id: String, $opp_data: GraphQLJSON) {
  CreateOpp(form_id: $form_id, opp_data: $opp_data) {
    opp_key
  }
}`;
const [createOpp, { loading: createOppLoading, error: createOppError,data:createOppdata }] =
useMutation(CreateOpportunity, {
  onCompleted: (data) => {
    ////console.log("OnSumbit", data);
    navigate(`/thankyou?signature=${signature}&context=${paramsContext}`);
  },
});
return {createOpp, createOppLoading, createOppError, createOppdata}
}

function UpdateOpp(signature, paramsContext){
  // ////console.log("signature", signature,"Val",paramsContext);

  const navigate = useNavigate();
  const updateOpportunity = gql`
  mutation UpdateOpp(
    $form_id: String
    $opp_key: String
    $opp_data: GraphQLJSON
  ) {
    UpdateOpp(form_id: $form_id, opp_key: $opp_key, opp_data: $opp_data) {
      opp_key
    }
  }
`;
const [UpdateOppInDB, { loading: updateOppLoading, error: updateOppError,data:updateOppData }] =
useMutation(updateOpportunity, {
  onCompleted: (data) => {
    ////console.log("OnSumbit", data);
    navigate(`/thankyou?signature=${signature}&context=${paramsContext}`);
  },
});
return {UpdateOppInDB, updateOppLoading, updateOppError, updateOppData}
}

function CountFormEntries(formId){
  const CountFormEntriesInOneCall= gql`
  query CountFormEntries($formId: String, $countFormEntriesTodayFormId2: String) {
    countFormEntries(form_id: $formId) {
      count
    }
    countFormEntriesToday(form_id: $countFormEntriesTodayFormId2) {
      count
    }
  }`
  const {
    loading: countFormEntriesInOneCallLoading,
    error: countFormEntriesInOneCallError,
    data: countFormEntriesInOneCallData,
  } = useQuery(CountFormEntriesInOneCall, {
    variables: { formId: formId,countFormEntriesTodayFormId2: formId 

    },
    skip: formId === undefined || formId === null,
  });
   ////console.log(">>cc", countFormEntriesInOneCallData?.countFormEntries.count,countFormEntriesInOneCallData?.countFormEntriesToday.count
   

  return {countFormEntriesInOneCallLoading,countFormEntriesInOneCallError,countFormEntriesInOneCallData}
}
function GetFormDataForFormList(currentPage,postsPerPage,search,showTemplate){
  const GetForms = gql`
query Forms($offset: String,$search: String,$showTemplates: Boolean) {
  forms(offset: $offset,search: $search,showTemplates: $showTemplates) {
    form_id
    form_name
    status
    base_form_template
    form_type
    lead_form_type
    is_form_template
  }
}
`;
const {
  loading: getFormsLoading,
  error: getFormsError,
  data: getFormsData,
  fetchMore,
  refetch
} = useQuery(GetForms, {
variables: {
  offset:String(currentPage * postsPerPage - postsPerPage),
  search: search ,
  showTemplates: showTemplate

}
});
return {getFormsLoading, getFormsError, getFormsData, fetchMore,refetch}
}


function FormCount(searchQuery,showTemplates){
  const FormsCount= gql`
 query Query($search: String, $showTemplates: Boolean) {
  countForms(search: $search, showTemplates: $showTemplates)
}`
  const {
    loading: countTotalFormsLoading,
    error: countTotalFormsError,
    data: countTotalFormsData,
  } = useQuery(FormsCount, {
    variables: { search: searchQuery, showTemplates: showTemplates },
  });
  return {countTotalFormsLoading,countTotalFormsError,countTotalFormsData}
}

function DeleteForm(){
  const DeleteForm = gql`
  mutation DeleteForm($formId: String) {
    DeleteForm(form_id: $formId) {
      form_id
      team_id
    }
  }
`;
const [
  deleteForm,
  {
    loading: deleteFormLoading,
    error: deleteFormError,
    data: deleteFormData,
  },
] = useMutation(DeleteForm, {
  onCompleted: (data) => {
    //////console.log("data", data);
  
  },
});
return {deleteForm, deleteFormLoading, deleteFormError, deleteFormData}
}

function GetTeamIdByFubdomain(domain){
  const GetTeamIdByFubdomain = gql`
query GetTeamIdByFubdomain($fubDomain: String) {
  GetTeamIdByFubdomain(fub_domain: $fubDomain) {
    team_id
  }
}
`
  const {data: teamData, loading: teamLoading, error: teamError} = useQuery(GetTeamIdByFubdomain, {
    variables: { fubDomain: domain },
  })
  ////console.log("InsideGetTeamIdByFubfomain",teamData)
return {teamData, teamLoading, teamError}
}

function GetFieldMappingFieldsByTeamId(){
  const GetFieldMappingFieldsByTeamId = gql`
  query GetFieldMappingFieldsByTeamId {
    GetFieldMappingFieldsByTeamId {
      field_id
      field_name
      field_type
      input_type
      settings
      field_sub_type
      opportunity_field_id
    }
  }
`
  const {data: formFieldsData, loading: loadingFields, error: fieldMappingError} = useQuery(GetFieldMappingFieldsByTeamId)
  return {formFieldsData, loadingFields, fieldMappingError}
}

function GetFubData(type,skipVariable){
  const GetFubData = gql`
  query GetFubData($type: String!) {
  GetFubData(type: $type) {
    output
    status
    info
  }
}`
const {data, loading, error} = useQuery(GetFubData, {
  variables: { type: type },
  skip: skipVariable,
})
return {data, loading, error}
}

function GetFubAllData(type,skipVariable){
  const GetFubAllData = gql`
  query GetFubAllData($type: String!) {
  GetFubAllData(type: $type) {
    output
    status
    info
  }
}`
const {data, loading, error} = useQuery(GetFubAllData, {
  variables: { type: type },
  skip: skipVariable,
})
return {data, loading, error}
}

  return {
    // GetCustomFields,
    GetFormData,
    CreateFormEntry,
    GetFormEntries,
    FormEntry,
    UpdateForm,
    DeleteFormEntry,
    UpdateFormEntry,
    Opps,
    GetOppByOppId,
    UpdateOpp,
    DuplicateForm,
    UpdateFormName,
    UpdateFormSettings,
    CreateFieldFuntion,
    GetFormFields,
    GetFieldMappingFields,
    GetOppData,
    CreateOpp,
    UpdateOpp,
    CountFormEntries,
    GetFormDataForFormList,
    FormCount,
    DeleteForm,
    GetTeamIdByFubdomain,
    GetFieldMappingFieldsByTeamId,
    GetFubData,
    GetFubAllData
  };
}

export default useFormApiHelper;
