import React, { useState } from "react";
import UpdateApptAddress from "./UpdateApptAddress";
import { gql, useMutation } from "@apollo/client";
import { Modal, Button } from "react-bootstrap";

const DeleteApptAddress = gql`
  mutation DeleteApptAddress($addressType: String!) {
    DeleteApptAddress(address_type: $addressType) {
      output
      status
      info
    }
  }
`;

const ApptAddress = ({ address, refetch }) => {
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteApptAddress, { loading: deleting }] =
    useMutation(DeleteApptAddress);

  const handleCloseUpdateModal = () => setShowUpdateModal(false);

  const handleDeleteApptAddress = () => {
    deleteApptAddress({
      variables: {
        addressType: address?.address_type,
      },
    }).then(() => {
      setShowDeleteModal(false);
      refetch();
    });
  };

  return (
    <>
      <div>
        <div className="d-flex align-items-center border gap-3 rounded-3 bg-white p-3">
          <div className="flex-column d-flex col">
            <span className="fs-6">{address?.address_type}</span>
            <p className="small text-muted">{address?.address}</p>
          </div>
          <div onClick={() => setShowUpdateModal(true)}>
            <i className="iconify fs-3" data-icon="tabler-edit"></i>
          </div>
          <div onClick={() => setShowDeleteModal(true)}>
            <i className="iconify fs-3" data-icon="tabler-trash"></i>
          </div>
        </div>

        <UpdateApptAddress
          showUpdateModal={showUpdateModal}
          setShowUpdateModal={setShowUpdateModal}
          handleCloseModal={handleCloseUpdateModal}
          apptAddress={address}
          refetch={refetch}
        />
      </div>

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Do you really want to delete <strong>{address?.address_type}</strong>{" "}
          address? This action cannot be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={handleDeleteApptAddress}
            disabled={deleting}
          >
            {deleting ? "Deleting..." : "Delete"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ApptAddress;
