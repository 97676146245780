import React, { useState, useEffect } from "react";
import { useMutation, gql } from "@apollo/client";
import { Modal, Button, Alert } from "react-bootstrap";
import { Input } from "antd";

const UPDATE_APPT_ADDRESS = gql`
  mutation UpdateApptAddress($addressType: String!, $address: String) {
    UpdateApptAddress(address_type: $addressType, address: $address) {
      output
      status
      info
    }
  }
`;

const UpdateApptAddress = ({
  showUpdateModal,
  handleCloseModal,
  apptAddress,
  refetch,
}) => {
  const [updateApptAddress, { loading: updating, error }] =
    useMutation(UPDATE_APPT_ADDRESS);
  const [addressType, setAddressType] = useState(
    apptAddress?.address_type || ""
  );
  const [address, setAddress] = useState(apptAddress?.address || "");

  useEffect(() => {
    setAddressType(apptAddress?.address_type || "");
    setAddress(apptAddress?.address || "");
  }, [apptAddress]);

  const handleUpdateAddress = () => {
    updateApptAddress({
      variables: { addressType, address },
    })
      .then(() => {
        handleCloseModal();
        refetch();
      })
      .catch((error) => console.error("Update error:", error));
  };

  return (
    <Modal show={showUpdateModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Update Appointment Address</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && (
          <Alert variant="danger">Error updating: {error.message}</Alert>
        )}
        <div className="d-flex flex-column">
          <div className="mb-4">
            <label className="label mb-2">Address Type</label>
            <Input
              className="form form-control input-bn"
              value={addressType}
              onChange={(e) => setAddressType(e.target.value)}
              disabled
              required
            />
          </div>

          <div className="mb-4">
            <label className="label mb-2">Address</label>
            <Input
              className="form form-control input-bn"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={handleUpdateAddress}
          disabled={updating || !addressType || !address}
        >
          {updating ? "Updating..." : "Update"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateApptAddress;
