import React, { useContext, useEffect } from "react";
import { boardConetxt } from "../../../pages/form-builder/dashboard/ContextHelper";
import GetStyleClasses from "./BoardElementsSettingsUi/GetStyleClasses";
import { Controller, useFormContext } from "react-hook-form";
import { useLocation } from "react-router-dom";

export const CheckBoxUi = ({
  elementid,
  showHide,
  oppFields,
  previewFlag = false,
}) => {
  const { board } = useContext(boardConetxt);
  const disableElementForBuild = window.location.pathname?.includes("build");

  const {
    register,
    formState: { errors },
    control,
    setValue,
  } = useFormContext();
  const ElementSettingsData = board?.find(
    (item) => String(item?.elementid) === String(elementid)
  )?.settings;
  useEffect(() => {
    if (oppFields) {
      const formattedValue = Array.isArray(oppFields)
        ? oppFields
        : String(oppFields).split(",").map((val) => val.trim());  
      setTimeout(() => {
        setValue(normalizeFieldName(elementid), formattedValue);
      }, 0);
    }
  }, [oppFields, elementid, setValue]);
  
  
  const Location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(Location.search);
    const updatedParamValue = params.get(elementid);

    if (elementid && updatedParamValue) {
      setValue(elementid, updatedParamValue);
    }
  }, [Location.search, elementid, setValue]);

  const { label, hidden, instructions, required } = ElementSettingsData;

  const initialStyle = {
    display: showHide === "show" ? "none" : "block",
  };

  const ElementName = board?.find(
    (item) => String(item?.elementid) === String(elementid)
  )?.name;
  const checkingValidation = () => {
    if (Boolean(required) === true && String(showHide) === "show") {
      return false;
    } else if (Boolean(required) === false && String(showHide) === "show") {
      return false;
    } else if (Boolean(required) === true && String(showHide) === "hide") {
      return false;
    } else if (Boolean(required) === false && String(showHide) === "hide") {
      return false;
    } else if (Boolean(required) === true && Boolean(hidden) === true) {
      return false;
    } else if (Boolean(required) === true) {
      return true;
    } else {
      return false;
    }
  };
  const normalizeFieldName = (name) => {
    return name?.replace(/[.\s]/g, "_"); // Replace dots and spaces with underscores
  };
  return (
    <div
      className={`${(previewFlag && GetStyleClasses(elementid)) || ""} ${
        hidden && previewFlag ? "d-none" : ""
      }`}
      id={elementid}
      style={initialStyle}
    >
      {!hidden && (
        <>
          {/* Render Label */}
          <label>
            <span>
              {label || ElementName} {/* Simplified label fallback */}
              {required && <span style={{ color: "red" }}>*</span>}
            </span>
          </label>

          {/* Render Options if Available */}
          {ElementSettingsData?.options?.length ? (
            ElementSettingsData.options.map((item, i) => (
              <div className="mt-3" key={i}>
             <Controller
  control={control}
  name={normalizeFieldName(elementid)}
  rules={{
    required: {
      value: checkingValidation(),
      message: "This field is required",
    },
  }}
  render={({ field }) => (
    <input
      className="form-check-input"
      type="checkbox"
      id={item?.id}
      value={item?.value}
      disabled={disableElementForBuild}
      {...field}
      checked={field.value?.includes(item.value)} 
      onChange={(e) => {
        const newValue = e.target.checked
          ? [...(field?.value || []), item?.value] 
          : field?.value?.filter((v) => v !== item?.value); 
        field.onChange(newValue);
      }}
    />
  )}
/>

                <label className="form-check-label ms-2" htmlFor={item?.id}>
                  {item?.value}
                </label>
              </div>
            ))
          ) : (
            // Render fallback if no options are available
            <>
              <Controller
                control={control}
                name={normalizeFieldName(elementid)}
                render={() => <div className="mt-2"></div>}
              />
              <label
                className="form-check-label text-secondary"
                htmlFor="check-1"
              >
                No options are currently available. Please add options to
                proceed.
              </label>
            </>
          )}

          {/* Render Instructions */}
          <small className="text-secondary">{instructions}</small>

          {/* Render Errors */}
          <div className="d-flex flex-column gap-2">
            {errors?.[elementid]?.message && (
              <span className="text-danger">{errors[elementid]?.message}</span>
            )}
          </div>
        </>
      )}
    </div>
  );
};
