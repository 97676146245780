import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import mixpanel from "./mixpanelConfig";

const TeamSettings = gql`
  query GetTeamSettings {
    GetTeamSettings {
      team_id
    }
  }
`;

const TrackingProvider = () => {
  const location = useLocation();
  const { data } = useQuery(TeamSettings);
console.log(location.pathname)
  // Extract teamId using useMemo to prevent unnecessary re-renders
  const teamId = useMemo(() => data?.GetTeamSettings?.[0]?.team_id, [data]);

  useEffect(() => {
    if (!teamId) return;

    mixpanel.identify(teamId);
    mixpanel.register({ team_id: teamId });

    // Define path-to-event mapping
    const eventMapping = {
      "/forms/": "Form Builder Login",
      "/automation-builder": "Automation Builder Login",
    };

    const eventName = eventMapping[location.pathname];

    if (eventName) {
      const eventKey = eventName.replace(/\s+/g, "_");
      const visitKey = `visitCount_${eventKey}_${teamId}`;
      
      let storedCount = parseInt(localStorage.getItem(visitKey), 10) || 0;
      storedCount += 1;
      localStorage.setItem(visitKey, storedCount);

      mixpanel.people.set({
        [`visit_count_${eventKey}_${teamId}`]: storedCount, // Ensure teamId is included in tracking key
      });

      // Track event in Mixpanel
      mixpanel.track(eventName, {
        team_id: teamId,
        visit_count: storedCount,
      });
    }
  }, [teamId, location.pathname]);

  return null; // This component only tracks visits, nothing to render
};

export default TrackingProvider;
