import React, { useContext, useEffect, useState } from "react";
import { boardConetxt } from "../../../pages/form-builder/dashboard/ContextHelper";
import GetStyleClasses from "./BoardElementsSettingsUi/GetStyleClasses";
import { Controller, useFormContext } from "react-hook-form";
import { useLocation } from "react-router-dom";

export const DropDownUi = ({
  elementid,
  showHide,
  oppFields,
  previewFlag = false,
  IsaList,
  osaList,
  AdminList,
}) => {
  const disableElementForBuild = window.location.pathname.includes("build");

  const { board, setboard } = useContext(boardConetxt);
  const {
    formState: { errors },
    control,
    setValue,
  } = useFormContext();

  const [optValue, setOptValue] = useState([]);
  const [osaVal, setOsaVal] = useState([]);
  const [admin, setAdmin] = useState([]);
  const [leadSource, setLeadSource] = useState([]);

 const Location = useLocation();

    useEffect(() => {
      const params = new URLSearchParams(Location.search);
      const updatedParamValue = params.get(elementid);
    
      if (elementid && updatedParamValue) {
        setValue(elementid, updatedParamValue);
      }
    }, [elementid, setValue]);
  useEffect(() => {
    if (String(elementid) === "DropDown-assigned_to") {
      // Combine IsaList and osaList and remove duplicates
      const combinedList = [...(IsaList || []), ...(osaList || [])];
      const uniqueList = Array?.from(
        new Set(combinedList?.map((item) => item?.name || item))
      ).map((name, index) => ({
        id: index,
        value: name,
        isCheck: false,
      }));

      setLeadSource(uniqueList); // Use setLeadSource or a new state if needed

      const updatedBoard = board?.map((item) => {
        if (String(item?.elementid )=== String(elementid)) {
          return {
            ...item,
            settings: {
              ...item.settings,
              options: uniqueList,
            },
          };
        }
        return item;
      });

      setboard(updatedBoard); // Update the board with the unique options
    }
  }, [IsaList, osaList, elementid]);

  useEffect(() => {
    if (String(elementid) === "DropDown-opp_isa" && IsaList) {
      const newOptions = IsaList?.map((item, index) => ({
        id: index,
        value: item,
        isCheck: false,
      }));
      setOptValue(newOptions);
      const updatedBoard = board?.map((item) => {
        if (String(item?.elementid) === String(elementid)) {
          return {
            ...item,
            settings: {
              ...item.settings,
              options: newOptions,
            },
          };
        }
        return item;
      });
      setboard(updatedBoard);
    }
  }, [IsaList, elementid]);

  useEffect(() => {
    if (String(elementid) === "DropDown-opp_assigned_osa" && osaList) {
      const newOptions = osaList?.map((item, index) => ({
        id: index,
        value: item,
        isCheck: false,
      }));
      setOsaVal(newOptions);
      const updatedBoard = board?.map((item) => {
        if (String(item.elementid) === String(elementid)) {
          return {
            ...item,
            settings: {
              ...item.settings,
              options: newOptions,
            },
          };
        }
        return item;
      });

      setboard(updatedBoard);
    }
  }, [osaList, elementid]);

  useEffect(() => {
    if (String(elementid) === "DropDown-Admin" && AdminList && AdminList?.length > 0) {
      const newOptions = AdminList?.map((item, index) => ({
        id: index,
        value: item.name, // Ensure item.name exists
        isCheck: false,
      }));

      setAdmin(newOptions);

      const updatedBoard = board?.map((item) => {
        if (String(item.elementid) === String(elementid)) {
          return {
            ...item,
            settings: {
              ...item.settings,
              options: newOptions, // Update options with AdminList data
            },
          };
        }
        return item;
      });

      setboard(updatedBoard); // Set the updated board state
    } else if (!AdminList || AdminList?.length === 0) {
      // console.error("AdminList is empty or undefined");
    }
  }, [AdminList, elementid]);

  const ElementSettingsData = board?.find(
    (item) => String(item?.elementid) === String(elementid)
  )?.settings;

  const { label, instructions, required, hidden, options } =
    ElementSettingsData || {};


  const initialStyle = {
    display: showHide === "show" ? "none" : "block",
  };

  const ElementName = board?.find((item) => String(item?.elementid) === String(elementid))?.name;
  const currentOptions =
    String(elementid) === "DropDown-opp_isa"
      ? optValue
      : String(elementid) === "DropDown-opp_assigned_osa"
      ? osaVal
      : String(elementid) === "DropDown-Admin"
      ? admin
      : String(elementid) === "DropDown-assigned_to"
      ? leadSource
      : options;

  const isOptionsEmpty = Number(currentOptions?.length) === Number(0);

  const checkingValidation = () => {
    if (required && String(showHide) === "show") return false;
    if (!required && String(showHide)  === "show") return false;
    if (required && String(showHide)  === "hide") return false;
    if (!required && String(showHide)  === "hide") return false;
    if (required && hidden) return false;
    return required;
  };
  //console.log("elementid>",board)
  const normalizeFieldName = (name) => {
    return name?.replace(/[.\s]/g, "_"); // Replace dots and spaces with underscores
  };
  useEffect(() => {
    if (oppFields) {
      const normalizedFieldName = normalizeFieldName(elementid);
        
      setValue(normalizedFieldName, oppFields.trim(), {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  }, [oppFields, currentOptions, elementid, setValue]);

  useEffect(() => {
    if (currentOptions?.length > 0) {
      const normalizedFieldName = normalizeFieldName(elementid);
      const selectedValue = currentOptions?.find(item => item.isCheck)?.value || "";      
      setValue(normalizedFieldName, selectedValue, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  }, [currentOptions, elementid, setValue]);
  
  return (
    <div
    className={`${(previewFlag && GetStyleClasses(elementid)) || ""} ${
      hidden && previewFlag ? "d-none" : ""
    }`}      style={initialStyle}
      hidden={hidden && previewFlag}
    >
      <div className="form-floatding form-group">
        <label>
          <span className="d-flex mb-2 align-items-center">
            <span>
              {label === undefined || label === "" ? ElementName : label}
              {required ? <span style={{ color: "red" }}>*</span> : ""}
            </span>
          </span>
        </label>

        <Controller
  name={normalizeFieldName(elementid)}
  control={control}
  defaultValue={currentOptions?.find(item => item.isCheck)?.value || ""}
  render={({ field }) => {
    console.log("Field value in Controller:", field.value); // Check if the value is updating
    return (
      <select
        {...field}
        className="form-select input-bn"
      >
        <option value="">Choose an option</option>
        {currentOptions?.map((item) => (
          <option key={item?.id} value={item?.value}>
            {item?.value}
          </option>
        ))}
      </select>
    );
  }}
/>

      </div>
      <small className="text-secondary">{instructions}</small>
      <div className="d-flex flex-column gap-2">
        {isOptionsEmpty && (
          <span className="text-secondary">
            {" "}
            No options are currently available. Please add options to proceed.{" "}
          </span>
        )}
        {errors && (
          <span className="text-danger">{errors[elementid]?.message}</span>
        )}
      </div>
    </div>
  );
};