import React, { useEffect, useState, useRef } from "react";
import { DatePicker, TimePicker } from "antd";
import dayjs from "dayjs";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";

const formnames = gql`
  query FormsNames($teamId: String) {
    formsNames(team_id: $teamId) {
      form_id
      form_name
      form_type

    }
  }
`;
const UPDATE_FORM_SETTINGS = gql`
  mutation UpdateFormSettings(
    $teamId: String!
    $formId: String
    $formRedirectionConditionsSetting: GraphQLJSON
  ) {
    UpdateFormSettings(
      team_id: $teamId
      form_id: $formId
      form_redirection_conditions_setting: $formRedirectionConditionsSetting
    ) {
      team_id
      form_id
    }
  }
`;

export default function RedirectionSettingPage({
  GetFormData,
  formFields,
  teamid,
  setFormLoader,
}) {
  const params = useParams();

  const [conditions, setConditions] = useState([
    [
      {
        data_type: "",
        expected_value: "",
        operator: "equals",
        field_id: "",
        logic: "and", // Default logic
      },
    ],
  ]);
  const [redirectOptions, setRedirectOptions] = useState([]);
  const [redirection, setRedirection] = useState({
    type: "",
    url: "",
    form: "",
  });


  useEffect(() => {
    if (GetFormData) {
      setRedirectOptions(GetFormData?.form_redirection_conditions_setting);
    }
  }, [GetFormData]);

  const { data: formnamesdata } = useQuery(formnames, {
    variables: { teamId: teamid },
  });
  const [editingRedirectionId, setEditingRedirectionId] = useState(null); // Track the redirection being edited
  //console.log("conditions", conditions);
  const createCondition = (logic) => ({
    data_type: "",
    expected_value: "",
    operator: "equals",
    field_id: "",
    logic,
  });

  const addConditionWithLogic = (logic) => {
    setConditions((prev) => {
      const lastGroup = prev[prev?.length - 1];

      if (logic === "and") {
        // Check if the last group exists
        if (lastGroup) {
          // Append to the last group regardless of its logic
          return [
            ...prev.slice(0, -1), // Retain all groups except the last one
            [...lastGroup, createCondition(logic)], // Add new condition to the last group
          ];
        } else {
          // If no groups exist, create a new group for "AND"
          return [...prev, [createCondition(logic)]];
        }
      } else if (logic === "or") {
        // Always create a new group for "OR"
        return [...prev, [createCondition(logic)]];
      }

      // Default fallback: return the unchanged state
      return prev;
    });
  };

  const updateCondition = (groupIndex, conditionIndex, updatedField) => {
    setConditions((prev) =>
      prev?.map((group, gIndex) =>
        gIndex === groupIndex
          ? group?.map((condition, cIndex) =>
              cIndex === conditionIndex
                ? { ...condition, ...updatedField }
                : condition
            )
          : group
      )
    );
  };
  const defaultCondition = conditions[0]?.[0]; // assuming the first condition of the first group is the default

  const removeCondition = (groupIndex, conditionIndex) => {
    setConditions(
      (prev) =>
        prev
          ?.map((group, gIndex) =>
            gIndex === groupIndex
              ? group.filter((_, cIndex) => cIndex !== conditionIndex) // Remove condition by index
              : group
          )
          ?.filter((group) => group?.length > 0) // Remove empty groups
    );
    setRedirectOptions((prev) =>
      prev?.map((option) => ({
        ...option,
        conditions: option.conditions.filter(
          (_, cIndex) => cIndex !== conditionIndex
        ),
      }))
    );
  };



  const [errorMessage, setErrorMessage] = useState("");
  const [hasStartedTyping, setHasStartedTyping] = useState(false);

  const handleRedirectionChange = (e) => {
    const { name, value } = e.target;

    if (!hasStartedTyping) {
      setHasStartedTyping(true); // Track that the user has started typing
    }

    // Validate the URL dynamically
    const error = validateUrl(value);
    setErrorMessage(error);

    setRedirection((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Function to validate URL and return an error message
  const validateUrl = (url) => {
    if (!url) {
      return "URL cannot be empty.";
    }

    try {
      new URL(url); // Check if the URL is valid
      return ""; // No error
    } catch {
      return "Please enter a valid URL.";
    }
  };

  const saveRedirection = () => {
    if (editingRedirectionId !== null) {
      // Edit the existing redirection
      setRedirectOptions((prev) =>
        prev.map((option) =>
          option.id === editingRedirectionId
            ? { ...option, ...redirection, conditions } // Update the conditions of the redirection
            : option
        )
      );
      setEditingRedirectionId(null); // Reset editing state
    } else {
      // Add a new redirection
      setRedirectOptions((prev) => [
        ...prev,
        {
          ...redirection,
          id: Math.floor(1000 + Math.random() * 9000),
          conditions, // Save the conditions with redirection
        },
      ]);
    }

    // Reset redirection state and conditions
    setRedirection({
      type: "",
      url: "",
      form: "",
    });
    setConditions([
      [
        {
          data_type: "",
          expected_value: "",
          operator: "equals",
          field_id: "",
          logic: "and", // Default logic
        },
      ],
    ]);
  };

  const deleteRedirection = (id) => {
    setRedirectOptions((prev) => {
      const updatedOptions = prev?.filter((option) => option?.id !== id);
      //console.log("Updated redirectOptions:", updatedOptions);
      return updatedOptions;
    });
  };

  const editRedirection = (id) => {
    const selectedOption = redirectOptions?.find((option) => option?.id === id);
    setRedirection(selectedOption);
    setConditions(selectedOption?.conditions); // Populate conditions with the existing data
    setEditingRedirectionId(id); // Set the editing state
  };

  const [updateFormSetting] = useMutation(UPDATE_FORM_SETTINGS);

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false; // Set to false after the first render
      return; // Skip the first render
    }

    if (redirectOptions?.length >= 0) {
      setFormLoader(true);
      updateFormSetting({
        variables: {
          teamId: String(teamid),
          formId: params.id,
          formRedirectionConditionsSetting: redirectOptions,
        },
      }).finally(() => {
        setFormLoader(false);
      });
    }
  }, [redirectOptions]);

  const renderFieldInput = (condition, groupIndex, conditionIndex) => {
    const field = formFields?.find((f) => f?.elementid === condition?.field_id);

    switch (field?.type) {
      case "DropDown":
      case "RadioButton":
      case "CheckBox":
        return field.settings?.options ? (
          <select
            id={`valueInput-${groupIndex}-${conditionIndex}`}
            className="form-select"
            value={condition.expected_value}
            onChange={(e) =>
              updateCondition(groupIndex, conditionIndex, {
                expected_value: e.target.value,
              })
            }
          >
            <option value="">Select Value</option>
            {field.settings.options.map((option, optIndex) => (
              <option key={optIndex} value={option.value}>
                {option.value}
              </option>
            ))}
          </select>
        ) : (
          <p className="text-muted">No options available</p>
        );

      case "Ratings":
        return (
          <select
            id={`valueInput-${groupIndex}-${conditionIndex}`}
            className="form-select"
            value={condition.expected_value}
            onChange={(e) =>
              updateCondition(groupIndex, conditionIndex, {
                expected_value: e.target.value,
              })
            }
          >
            <option value="">Select Value</option>
            {[1, 2, 3, 4, 5].map((num) => (
              <option key={num} value={num}>
                {num}
              </option>
            ))}
          </select>
        );

      case "Date":
        return (
          <DatePicker
            format="MM/DD/YYYY"
            value={
              condition.expected_value
                ? dayjs(condition.expected_value, "MM/DD/YYYY")
                : null
            }
            onChange={(date) =>
              updateCondition(groupIndex, conditionIndex, {
                expected_value: date ? date.format("MM/DD/YYYY") : "",
              })
            }
          />
        );

      case "Time":
        return (
          <TimePicker
            format="HH:mm"
            value={
              condition.expected_value
                ? dayjs(condition.expected_value, "HH:mm")
                : null
            }
            onChange={(time) =>
              updateCondition(groupIndex, conditionIndex, {
                expected_value: time ? time.format("HH:mm") : "",
              })
            }
          />
        );

      case "NumberField":
      case "Contact":
        return (
          <input
            type="number"
            id={`valueInput-${groupIndex}-${conditionIndex}`}
            className="form-control"
            value={condition.expected_value}
            onChange={(e) =>
              updateCondition(groupIndex, conditionIndex, {
                expected_value: e.target.value,
              })
            }
          />
        );

      default:
        return (
          <input
            type="text"
            id={`valueInput-${groupIndex}-${conditionIndex}`}
            className="form-control"
            value={condition.expected_value}
            onChange={(e) =>
              updateCondition(groupIndex, conditionIndex, {
                expected_value: e.target.value,
              })
            }
          />
        );
    }
  };
  const areConditionsValid = () => {
    // Check if any condition group or its fields are invalid
    return conditions?.some((group) =>
      group?.some(
        (condition) =>
          !condition?.field_id || !condition?.expected_value // If field_id or expected_value is missing
      )
    );
  };
  

  return (
    <>
      <div className="mx-auto bg-white d-flex flex-column flex-wrap rounded-3 border position-relative mb-5 p-4">
        <div className="w-100 d-flex flex-column m-0 mb-4 pb-2 border-bottom">
          <h5 className="fs-6 m-0 mb-2">
            Redirect Settings{" "}
            <span
              className="rounded-pill fw-light text-dark badge bg-warning"
              style={{ marginLeft: "4px" }}
            >
              {" "}
              Beta{" "}
            </span>{" "}
          </h5>
          <p className="small text-secondary">
          Where would you like to be redirected when the form is submitted.
          </p>
        </div>
        <h5 className="fs-6 m-0 mb-3">Redirect Conditions</h5>

        {conditions?.map((group, groupIndex) => (
          <div key={`group-${groupIndex}`} className="condition-group">
            {group?.map((condition, conditionIndex) => (
              <React.Fragment key={`condition-${groupIndex}-${conditionIndex}`}>
                <div className="d-flex align-items-end mb-4 bg-color-1 border p-3 rounded-2 position-relative">
                  <div className="col-md-4">
                    <label
                      htmlFor={`fieldSelect-${groupIndex}-${conditionIndex}`}
                      className="form-label mb-1 ms-1 small"
                    >
                      IF
                    </label>
                    <select
                      id={`fieldSelect-${groupIndex}-${conditionIndex}`}
                      className="form-select"
                      value={condition.field_id}
                      onChange={(e) =>
                        updateCondition(groupIndex, conditionIndex, {
                          field_id: e.target.value,
                          data_type:
                            formFields?.find(
                              (f) => f?.elementid === e.target.value
                            )?.type || "",
                        })
                      }
                    >
                      <option value="">Select</option>
                      {formFields?.filter(
                  (field) =>
                    field?.type !== "FreeText" && field?.type !== "Headings" // Exclude FreeText and heading types
                )
                ?.map((field) => (
                        <option key={field?.elementid} value={field?.elementid}>
                          {field?.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <hr className="col mb-3" />

                  <div className="col-md-4">
                    <label
                      htmlFor={`operatorSelect-${groupIndex}-${conditionIndex}`}
                      className="form-label mb-1 ms-1 small"
                    >
                      Operator
                    </label>
                    <select
                      id={`operatorSelect-${groupIndex}-${conditionIndex}`}
                      className="form-select"
                      value={condition?.operator}
                      onChange={(e) =>
                        updateCondition(groupIndex, conditionIndex, {
                          operator: e.target.value,
                        })
                      }
                    >
                      <option value="equals">Equals</option>
                      <option value="not_equals">Not Equals</option>
                      <option value="greater_than">Greater Than</option>
                      <option value="less_than">Less Than</option>
                      <option value="contains">Contains</option>
                      <option value="not_contains">Not Contains</option>
                      <option value="starts_with">Starts With</option>
                      <option value="ends_with">Ends With</option>
                    </select>
                  </div>

                  <hr className="col mb-3" />

                  <div className="col-md-3">
                    <label
                      htmlFor={`valueInput-${conditionIndex}`}
                      className="form-label mb-1 ms-1 small"
                    >
                      Value
                    </label>
                    {renderFieldInput(condition, groupIndex, conditionIndex)}
                  </div>

                  <div className="ms-3">
                    {condition !== defaultCondition && (
                      <button
                        className="bg-white p-0 border rounded-circle fm-condi-close"
                        onClick={() =>
                          removeCondition(groupIndex, conditionIndex)
                        }
                      >
                        <i
                          className="iconify small text-danger"
                          data-icon="mdi:close-circle"
                        ></i>
                      </button>
                    )}
                  </div>
                </div>
                {group?.length > 1 && conditionIndex < group?.length - 1 && (
                  <div className="text-center my-2 mb-3">
                    <strong>AND</strong>
                  </div>
                )}
              </React.Fragment>
            ))}
            {/* Add "OR" between groups */}
            {conditions?.length > 1 && groupIndex < conditions?.length - 1 && (
              <div className="text-center my-2 mb-3">
                <strong>OR</strong>
              </div>
            )}
          </div>
        ))}

        <div className="d-flex align-content-center gap-2">
          <div
            onClick={() => addConditionWithLogic("and")}
            className="btn btn-primary px-4"
          >
            AND Condition
          </div>
          <div
            onClick={() => addConditionWithLogic("or")}
            className="btn btn-primary px-4"
          >
            OR Condition
          </div>
        </div>

        <div className="mt-4">
          <h5 className="fs-6 m-0 mb-3">Redirect Option </h5>

          <div className="d-flex flex-column gap-2">
            <div>
              <input
                type="radio"
                name="type"
                value="form"
                checked={redirection.type === "form"}
                onChange={handleRedirectionChange}
              />
              <label className="ms-2">Redirect to Interface Form</label>
            </div>

            <div>
              <input
                type="radio"
                name="type"
                value="website"
                checked={redirection.type === "website"}
                onChange={handleRedirectionChange}
              />
              <label className="ms-2">Redirect to Other Website</label>
            </div>
          </div>

          {redirection.type === "form" && (
            <div className="mt-3">
              <select
                name="form"
                value={redirection.form}
                onChange={handleRedirectionChange}
                className="form-select"
              >
                <option value="">Select Form</option>
                {formnamesdata?.formsNames
                  ?.filter((form) => form?.form_type !== "Lead Forms") // Filter out Lead Forms
                  ?.map((form) => (
                    <option key={form?.form_id} value={form?.form_id}>
                      {form?.form_name}
                    </option>
                  ))}
              </select>
            </div>
          )}

          {redirection?.type === "website" && (
            <div className="mt-3">
              <input
                type="text"
                placeholder="Enter URL"
                name="url"
                value={redirection?.url}
                onChange={handleRedirectionChange}
                className={`form-control ${
                  redirection?.url?.length > 0 && errorMessage ? "is-invalid" : ""
                }`} // Show invalid className only if typing has started
              />
              {redirection?.url?.length > 0 && errorMessage && (
                <small className="text-danger">{errorMessage}</small>
              )}
            </div>
          )}

          {/* <div className="btn btn-success btn-sm px-4 mt-4" onClick={saveRedirection}>
            {editingRedirectionId !== null
              ? "Update Redirection"
              : "Save Redirection"}
          </div> */}
          <div
            className={`btn btn-success btn-sm px-4 mt-4 ${
              !redirection?.type ||
              (redirection?.type === "form" && !redirection?.form) ||
              (redirection?.type === "website" && !redirection?.url) || areConditionsValid()
                ? "disabled"
                : ""
            }`}
            onClick={saveRedirection}
            disabled={
              !redirection?.type ||
              (redirection?.type === "form" && !redirection?.form) ||
              (redirection?.type === "website" && !redirection?.url) || areConditionsValid()
            }
          >
            {editingRedirectionId !== null
              ? "Update Redirection"
              : "Save Redirection"}
          </div>
        </div>
      </div>

      {redirectOptions?.length > 0 && (
        <div className="mt-5 border-top pt-4">
          <h5 className="fs-6 m-0 mb-3">Saved Redirection</h5>

          {redirectOptions?.map((option, index) => (
            <div
              key={index}
              className="bg-white d-flex flex-column flex-wrap rounded-3 border position-relative mb-4"
            >
              <div className="d-flex w-100 rounded-top justify-content-between align-items-center border-bottom bg-color-1 p-3">
                <strong>Redirection {index + 1}</strong>

                <div className="d-flex align-items-center gap-2">
                  <button
                    className=""
                    onClick={() => editRedirection(option.id)}
                  >
                    <i className="iconify fs-2" data-icon="mdi:edit"></i>
                  </button>
                  <button
                    className=""
                    onClick={() => deleteRedirection(option.id)}
                  >
                    <i
                      className="iconify fs-2 text-danger"
                      data-icon="mdi:trash"
                    ></i>
                  </button>
                </div>
              </div>

              <div className="p-3 d-flex flex-column gap-2">
                <div className="">
                  {option?.conditions?.map((group, groupIndex) => (
                    <span key={`group-${groupIndex}`}>
                      {group?.map((condition, conditionIndex) => (
                        <div
                          className="d-flex flex-column justify-content-center"
                          key={`condition-${groupIndex}-${conditionIndex}`}
                        >
                          <p className="m-0 d-flex flex-wrap align-items-center bg-color-1 p-2 rounded-2 gap-2 mb-2">
                            <b className="">If</b>
                            <span className="border dot-border dt-cont p-2 px-3 rounded m-0">
                              {condition?.field_id}{" "}
                              <b className="text-primary">
                                {condition?.operator}
                              </b>{" "}
                              to {condition?.expected_value}
                            </span>
                          </p>

                          {/* Add "AND" between conditions in the same group */}
                          {group?.length > 1 &&
                            conditionIndex < group?.length - 1 && (
                              <p className="m-0 mb-2 ms-1">
                                <strong>AND</strong>
                              </p>
                            )}
                        </div>
                      ))}

                      {/* Add "OR" between groups */}
                      {option?.conditions?.length > 1 &&
                        groupIndex < option.conditions?.length - 1 && (
                          <p className="m-0 mb-2 ms-1">
                            <strong>OR</strong>
                          </p>
                        )}

                      {/* Show "redirect to" only once at the end of all groups */}
                      {groupIndex === option?.conditions?.length - 1 && (
                        <p className="border p-2 px-3 rounded m-0 mt-2">
                          Then redirect to{" "}
                          <span className="text-primary ms-2">
                            {option?.type === "form"
                              ? formnamesdata?.formsNames?.find(
                                  (form) => form?.form_id === option?.form
                                )?.form_name
                              : option?.url}
                          </span>
                        </p>
                      )}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
}
