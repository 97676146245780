const dataDictionary = {
  "event.event_data.timestamp": "Date and time of event",
  "event.event_data.eventCreated": "Date and time of event creation",
  "person.custom": "Custom field <field name>",
  "person.dealStage": "Deal Stage",
  "person.price": "Person Price",
  "person.collaborators[0].name": "Collaborator Name",
  "person.collaborators[0].assigned": "Collaborator Assigned",
  "person.collaborators[0].id": "Collaborator User ID",
  "person.collaborators[0].role": "Collaborator User Role",
  "person.id": "Person ID / Follow Up Boss Lead ID",
  "person.lastLeadActivity": "Last Activity Date and Time",
  "person.createdById": "Person Created by User ID",
  "person.lastIncomingCall": "Person Last Incoming Call",
  "person.lastEmail": "Person Last Email",
  "person.emailsSent": "Person Number of Emails Sent",
  "person.updatedById": "Person updated by User ID",
  "person.lastSentText": "Person Last Sent Text",
  "person.lastName": "Person Last Name",
  "person.lastDeliveredMarketingCampaign": "Person Last delivered marketing campaign",
  "person.propertiesViewed": "Person Number of properties viewed",
  "person.assignedPondId": "Assigned Pond ID",
  "person.lastInboxAppMessage": "Person Last inbox app message",
  "person.stageId": "Person stage ID",
  "person.nextTaskHasTime": "Person Next Task has Time",
  "person.dealName": "Name of the deal",
  "person.stage": "Stage of the Person",
  "person.lastActivity": "Person Last activity",
  "person.assignedUser Id": "Assigned Agent User ID",
  "person.sourceId": "Lead Source ID",
  "person.lastReceivedEmail": "Last Received Email",
  "person.callsIncoming": "Total incoming calls",
  "person.source": "Person Source",
  "person.dealPrice": "Deal Price",
  "person.lastSentEmail": "Person Last Sent Email",
  "person.emailsReceived": "Total Emails Received",
  "person.createdVia": "Person Created Via",
  "person.created": "Person Created",
  "person.assignedLenderName": "Assigned Lender",
  "person.textsSent": "Person Total texts sent",
  "person.firstName": "Person first name",
  "person.textsReceived": "Person Total texts received",
  "person.background": "Person Background data",
  "person.name": "Person name",
  "person.assignedLenderId": "Assigned Lender User ID",
  "person.updated": "Person Updated",
  "person.lastOutgoingCall": "Person Last outgoing call",
  "person.callsDuration": "Person Total Call Duration",
  "person.assignedTo": "Assigned Agent",
  "person.callsOutgoing": "Person Total outgoing calls",
  "person.lastReceivedText": "Person Last Received Text",
  "person.lastText": "Person Last Text",
  "person.lastCall": "Person Last Call",
  "person.tags[0]": "Tags",
  "person.addresses[0].country": "Country",
  "person.addresses[0].state": "State",
  "person.addresses[0].code": "Postal Code",
  "person.addresses[0].city": "City",
  "person.addresses[0].street": "Street",
  "person.phones[0].isLandline": "Person Phone is Landline",
  "person.phones[0].isPrimary": "Person Phone is Primary",
  "person.phones[0].normalized": "Person Phone Normalized",
  "person.phones[0].type": "Person Phone Type",
  "person.phones[0].value": "Person Phone value",
  "person.phones[0].status": "Person Phone Status",
  "person.emails[0].type": "Person Email Type",
  "person.emails[0].value": "Person Email Value",
  "person.emails[0].isPrimary": "Person Email is Primary",
  "person.emails[0].status": "Person Email Status",
  "note.updatedBy": "Note updated by User",
  "note.created": "Note created",
  "note.subject": "Note Subject",
  "note.updatedById": "Note updated by User ID",
  "note.body": "Note Body",
  "note.createdBy": "Note created by User",
  "note.isHtml": "Note is HTML",
  "note.updated": "Note Updated",
  "note.createdById": "Note created by User ID",
  "email.date": "Email Date",
  "email.emailAccountId": "Email Account ID",
  "email.read": "Email Read",
  "email.created": "Email Created",
  "email.hasEmailDraft": "Email has draft",
  "email.bounced": "Email Bounced",
  "email.userId": "Email by User ID",
  "email.threadId": "Email Thread ID",
  "email.unsubscribed": "Email Unsubscribed",
  "email.hasAttachments": "Email has Attachments",
  "text.lastName": "Text Last Name",
  "text.deliveryStatusError": "Text Delivery Status Error",
  "text.isIncoming": "Text is incoming",
  "text.createdById": "Created by User ID",
  "text.created": "Text Created",
  "text.deliveryStatusDate": "Text Delivery Status Date",
  "text.userName": "Text User Name",
  "text.userId": "Text User ID",
  "text.sent": "Text Sent",
  "text.fromNumber": "Text From Number",
  "text.firstName": "Text First Name",
  "text.name": "Text Full Name",
  "text.personId": "Text Person ID",
  "text.toNumber": "Text To Number",
  "text.deliveryStatusErrorCode": "Text Delivery Status Error Code",
  "text.deliveryStatus": "Text Delivery Status",
  "text.status": "Text Status",
  "call.lastName": "Call Last Name",
  "call.note": "Call Note",
  "call.isIncoming": "Call Is Incoming",
  "call.duration": "Call Duration",
  "call.createdById": "Call created by user ID",
  "call.created": "Call created",
  "call.updatedById": "Call updated by user ID",
  "call.userName": "Call User Name",
  "call.userId": "Call User ID",
  "call.fromNumber": "Call from Number",
  "call.firstName": "Call First Name",
  "call.phone": "Call Phone Number",
  "call.name": "Call Name",
  "call.personId": "Call Person ID",
  "call.ringDuration": "Call Ring Duration",
  "call.toNumber": "Call to Number",
  "appointment.created": "Appointment Created",
  "appointment.timezone": "Appointment Timezone",
  "appointment.start": "Appointment Start Time",
  "appointment.description": "Appointment Description",
  "appointment.outcomeId": "Appointment Outcome by ID",
  "appointment.updatedById": "Appointment Updated by ID",
  "appointment.title": "Appointment Title",
  "appointment.type": "Appointment Type",
  "appointment.allDay": "Is Appointment All Day",
  "appointment.originFub": "Appointment Origin",
  "appointment.isEditable": "Appointment Editable",
  "appointment.invitees[0].name": "Appointment Invitees Name",
  "appointment.invitees[0].personId": "Appointment Invitees Person ID",
  "appointment.invitees[0].relationshipId": "Appointment Invitees Relationship ID",
  "appointment.invitees[0].userId": "Appointment Invitees User ID",
  "appointment.invitees[0].email": "Appointment Invitees Email",
  "appointment.end": "Appointment End",
  "appointment.location": "Appointment Location",
  "appointment.typeId": "Appointment Type ID",
  "appointment.updated": "Appointment Updated",
  "appointment.createdById": "Appointment created by User ID",
  "appointment.outcome": "Appointment Outcome"
};

export default dataDictionary;
