import React, { useContext } from "react";
import { boardConetxt } from "../../../pages/form-builder/dashboard/ContextHelper";
import GetStyleClasses from "./BoardElementsSettingsUi/GetStyleClasses";
import DOMPurify from "dompurify";

export const FreeTextUi = ({ elementid, showHide, previewFlag = false }) => {
  const { board } = useContext(boardConetxt);
  const ElementSettingsData = board.find(
    (item) => item?.elementid === elementid
  ).settings;

  const { label, hidden, instructions } = ElementSettingsData;
  const initStyle = {
    display: showHide === "show" ? "none" : "block",
  };

  const ElementName = board.find((item) => item?.elementid === elementid).name;


  const sanitizedLabel = DOMPurify.sanitize(label || "", {
    ADD_ATTR: ["target", "rel"], // Allow target and rel attributes
  }).replace(
    /<a /g,
    '<a target="_blank" rel="noopener noreferrer" style="color: blue; text-decoration: underline;" '
  );

  // Extract plain text content from the label and decode HTML entities
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = DOMPurify.sanitize(label || "", { ALLOWED_TAGS: [] });
  const plainText = tempDiv.textContent || tempDiv.innerText;

  // Check if the label contains a hyperlink
  const hasHyperlink = label && /<a\s+href=.*?>.*?<\/a>/.test(label);

  return (
    <>
    <div
      className={`${(previewFlag && GetStyleClasses(elementid)) || ""} ${
        hidden && previewFlag ? "d-none" : ""
      }`}
      style={initStyle}
      hidden={hidden && previewFlag}
    >
      <div className="mt-2">
        {/* Render FreeText as plain text, rest as HTML */}
        {label ? (
          hasHyperlink ? (
            <div
              dangerouslySetInnerHTML={{
                __html: sanitizedLabel.replace(
                  plainText,
                  `<span>${plainText}</span>`
                ),
              }}
            />
          ) : (
            <h6>{plainText}</h6>
          )
        ) : (
          <h6>{ElementName}</h6>
        )}

        {/* Render instructions if available */}
        {instructions && <p className="text-muted">{instructions}</p>}
      </div>
    </div>
    </>
  );
};
