import React, { useState, useEffect, useRef } from "react";
import { gql, useMutation } from "@apollo/client";

const UPDATE_THEME_MUTATION = gql`
  mutation UpdateFormSettings(
    $teamId: String!
    $colorScheme: String
    $formId: String
    $showFormLogo: Boolean
  ) {
    UpdateFormSettings(
      team_id: $teamId
      color_scheme: $colorScheme
      form_id: $formId
      show_form_logo: $showFormLogo
    ) {
      team_id
      form_id
    }
  }
`;

const GET_PRESIGNED_URL = gql`
  mutation GetPresignedUrl($fileName: String!, $fileType: String!) {
    getPresignedUrl(fileName: $fileName, fileType: $fileType)
  }
`;

const SAVE_IMAGE_URL = gql`
  mutation SaveImageUrl($formId: String!, $imageUrl: String!) {
    saveImageUrl(formId: $formId, imageUrl: $imageUrl) {
      success
    }
  }
`;

export const FormCustomThemeLogo = ({ GetFormData }) => {
  const formId = GetFormData?.form_id;
  const teamId = GetFormData?.team_id;
  const fileInputRef = useRef(null); // File input reference

  const [bgColor, setBgColor] = useState(GetFormData?.color_scheme || "#065697");
  const [logo, setLogo] = useState(GetFormData?.logo_url || null);
  const [disableUpload, setDisableUpload] = useState(GetFormData?.show_form_logo || false);

  const [updateFormSettings, { loading: FormUpdateLoading, error }] = useMutation(UPDATE_THEME_MUTATION);
  const [getPresignedUrl] = useMutation(GET_PRESIGNED_URL);
  const [saveImageUrl] = useMutation(SAVE_IMAGE_URL);
  const [FileUploadLoading, setFileUploadLoading] = useState(false);

  useEffect(() => {
    if (
      bgColor !== GetFormData?.color_scheme ||
      disableUpload !== GetFormData?.show_form_logo
    ) {
      updateFormSettings({
        variables: {
          teamId: String(teamId),
          formId: String(formId),
          colorScheme: bgColor || null,
          showFormLogo: disableUpload,
        },
      });
    }
  }, [bgColor, GetFormData, teamId, formId, updateFormSettings, disableUpload]);

  const handleColorChange = (e) => {
    setBgColor(e.target.value);
  };

  const handleFileChangeClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleLogoUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    
    setFileUploadLoading(true);
    try {
      const { data } = await getPresignedUrl({
        variables: { fileName: file.name, fileType: file.type },
      });
      const presignedUrl = data.getPresignedUrl;
      const s3FileUrl = presignedUrl.split("?")[0];

      await fetch(presignedUrl, {
        method: "PUT",
        body: file,
        headers: { "Content-Type": file.type },
      });

      await saveImageUrl({ variables: { formId: formId, imageUrl: s3FileUrl } });
      setLogo(s3FileUrl);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
    setFileUploadLoading(false);
  };

  const handleRemoveLogo = async () => {
    await saveImageUrl({ variables: { formId: formId, imageUrl: "" } });
    setLogo(null);
  };

  return (
    <div
      className="mx-auto bg-white d-flex flex-column flex-wrap rounded-3 border position-relative mb-5 p-4"
      style={{ backgroundColor: bgColor }}
    >
      <h5 className="d-flex align-items-center fs-6 m-0 mb-2">
        <span>
          <i
            className="iconify me-2 popy"
            data-icon="fluent:color-24-filled"
            data-inline="false"
            style={{ fontSize: "24px", width: "24px", height: "24px" }}
          />
        </span>
        Form Theme
        <span className="rounded-pill fw-light text-dark badge bg-warning" style={{ marginLeft: "8px" }}>
          Beta
        </span>
      </h5>
      <h5 className="small text-secondary mb-4">
        Customize form theme and add a custom logo to your forms
      </h5>
      <hr className="hr" />
      
      {/* Background Color Picker */}
      <div className="mb-3 d-flex justify-content-between align-items-center mt-2">
        <label className="form-label mb-0">Select Background Color</label>
        <input
          type="color"
          className="form-control form-control-color"
          style={{ width: "50px", height: "35px", cursor: "pointer" }}
          value={bgColor}
          onChange={handleColorChange}
        />
      </div>
      <hr className="hr" />
      
      {/* Logo Upload Section */}
      <div className="mb-3">
        <label className="form-label mb-3">Upload Custom Logo</label>
        <div
          className="border rounded p-3 text-center bg-light"
          style={{
            opacity: disableUpload ? 0.5 : 1,
            pointerEvents: disableUpload ? "none" : "auto",
          }}
        >
          <input
            type="file"
            id="logoUpload"
            accept="image/*"
            onChange={handleLogoUpload}
            disabled={disableUpload}
            style={{ display: "none" }}
            ref={fileInputRef}
          />

          {logo ? (
            <>
              <img src={logo} alt="Logo" style={{ maxWidth: "100px" }} />
              <div className="mt-2">
                <button
                  type="button"
                  className="btn btn-outline-primary btn-sm me-2"
                  onClick={handleFileChangeClick}
                >
                  Change
                </button>
                <button
                  className="btn btn-outline-danger btn-sm"
                  onClick={handleRemoveLogo}
                >
                  Remove
                </button>
              </div>
            </>
          ) : (
            <button
              type="button"
              className="btn btn-outline-primary btn-sm"
              onClick={handleFileChangeClick}
            >
              Upload
            </button>
          )}
        </div>

        <div className="form-check mt-2">
          <input
            className="form-check-input"
            type="checkbox"
            id="removeLogo"
            onChange={(e) => setDisableUpload(e.target.checked)}
            checked={disableUpload}
          />
          <label className="form-check-label" htmlFor="removeLogo">
            Prefer a cleaner look? Choose to remove the logo entirely
          </label>
        </div>
      </div>

      {/* Loading & Error Messages */}
      {(FileUploadLoading || FormUpdateLoading) && (
        <p className="text-primary">Updating settings...</p>
      )}
      {error && <p className="text-danger">Error updating settings</p>}
    </div>
  );
};
