import React, { useState } from "react";
import { useMutation, gql } from "@apollo/client";
import { Modal, Button, Alert } from "react-bootstrap";
import { Input } from "antd";

const CREATE_APPT_ADDRESS = gql`
  mutation CreateApptAddress($addressType: String!, $address: String!) {
    CreateApptAddress(address_type: $addressType, address: $address) {
      output
      status
      info
    }
  }
`;

const CreateApptAddress = ({ showModal, handleCloseModal, refetch }) => {
  const [createApptAddress, { loading: creating }] =
    useMutation(CREATE_APPT_ADDRESS);
  const [addressType, setAddressType] = useState("");
  const [address, setAddress] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleCreateAddress = () => {
    createApptAddress({
      variables: { addressType, address },
    })
      .then((response) => {
        const { status, info } = response.data.CreateApptAddress;

        if (status !== "200") {
          setErrorMessage(info);
        } else {
          setErrorMessage("");
          handleCloseModal();
          clearFields();
          refetch();
        }
      })
      .catch((error) => {
        console.error("Error creating address:", error);
        setErrorMessage(
          "An unexpected error occurred. Please try again later."
        );
      });
  };

  const closeModal = () => {
    handleCloseModal();
    clearFields();
    setErrorMessage("");
  };

  const clearFields = () => {
    setAddressType("");
    setAddress("");
  };

  return (
    <>
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Create Appointment Address</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorMessage && (
            <Alert
              variant="danger"
              onClose={() => setErrorMessage("")}
              dismissible
            >
              {errorMessage}
            </Alert>
          )}
          <div className="d-flex flex-column">
            <div className="mb-4">
              <label className="label mb-2">Address Type</label>
              <Input
                className="form form-control input-bn"
                value={addressType}
                onChange={(e) => setAddressType(e.target.value)}
                required
              />
            </div>

            <div className="mb-4">
              <label className="label mb-2">Address</label>
              <Input
                className="form form-control input-bn"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                required
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handleCreateAddress}
            disabled={creating || !addressType || !address}
          >
            {creating ? "Creating..." : "Create"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateApptAddress;
