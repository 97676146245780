import { gql } from "@apollo/client";
import React, { useState } from "react";
import { useEffect } from "react";
import Switch from "react-switch";
import { useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";

const UpdateAppointmentFormSettings =gql`
mutation UpdateFormSettings($teamId: String!, $formId: String, $inviteSentToClient: Boolean, $wufooIsaCalendarInvite: Boolean, $addFubRelationships: Boolean, $relationshipCalendarInvite: Boolean) {
  UpdateFormSettings(team_id: $teamId, form_id: $formId, invite_sent_to_client: $inviteSentToClient, wufoo_isa_calendar_invite: $wufooIsaCalendarInvite, add_fub_relationships: $addFubRelationships, relationship_calendar_invite: $relationshipCalendarInvite) {
    team_id
    form_id
  }
}   `;
export const AppointmentFormSettings = ({GetFormData}) => {
      // State to manage toggle switches
      const params = useParams();
  const [toggleSettings, setToggleSettings] = useState({
    // invite_sent_to_client: false,
    wufoo_isa_calendar_invite: false,
    add_fub_relationships: false,
    relationship_calendar_invite: false
  });
  useEffect(() => {
    if(GetFormData){
        setToggleSettings({
          //  invite_sent_to_client: GetFormData?.invite_sent_to_client,
            wufoo_isa_calendar_invite: GetFormData?.wufoo_isa_calendar_invite,
            add_fub_relationships: GetFormData?.add_fub_relationships,
            relationship_calendar_invite: GetFormData?.relationship_calendar_invite
        });
    }
    }
    , [GetFormData]);

  const [updateFormSettings] = useMutation(UpdateAppointmentFormSettings);




    const handleToggleChange = (key) => {
      setToggleSettings((prev) => {
        const updatedSettings = { ...prev, [key]: !prev[key] };
    
        updateFormSettings({
          variables: {
            teamId: String(GetFormData?.team_id),
            formId: params?.id,
            wufooIsaCalendarInvite: updatedSettings.wufoo_isa_calendar_invite,
            addFubRelationships: updatedSettings.add_fub_relationships,
            relationshipCalendarInvite: updatedSettings.relationship_calendar_invite
          }
        });
    
        return updatedSettings;
      });
    };
    

  return (
    <div className="mx-auto bg-white d-flex flex-column flex-wrap rounded-3 border position-relative mb-5 p-4">
         <div className="mb-5">
         <h5 className="d-flex align-items-center fs-6 m-0 mb-4">
          Form Settings    
             <span
          className="rounded-pill fw-light text-dark badge bg-warning"
          style={{ marginLeft: "8px" }}
        >
          Beta
        </span>
      </h5>
      <hr className="hr" />

    {/* Toggle Switches */}
    <div className="mt-4">
      {[
        // { key: "invite_sent_to_client", label: "Invite Sent to Client" },
        { key: "wufoo_isa_calendar_invite", label: "ISA Calendar Invite" },
        { key: "add_fub_relationships", label: "Add FUB Relationships" },
        { key: "relationship_calendar_invite", label: "Relationship Calendar Invite" }
      ]?.map(({ key, label }) => (
        <div key={key} className="d-flex align-items-center justify-content-between mb-2">
          <span>{label}</span>
          <Switch
           checked={toggleSettings[key]}
           handleDiameter={10}
           uncheckedIcon={false}
           checkedIcon={false}
           width={40}
             onColor="#0d6efd"
          offColor="#ccc"
           className="switch-rect mb-2"
           height={20}
           offstyle="primary"
           onChange={() => handleToggleChange(key)}
          />
        </div>
      ))}
    </div>
  </div></div>
  )
}
