export const FORM_TYPES = [
    { label: "Opportunity Forms", value: "Opportunity Forms" },
    { label: "Lead Forms", value: "Lead Forms" },
  ];
export  const templateOptions = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" }
  ];
  
export const fieldOptions = [
    { id: 1, name: "Price", description: "Linked To FUB Transaction Price",elementid:"NumberField-opp_price" },
    { id: 2, name: "Lead Last Name", description: "Linked To Lead Last Name",elementid:"InputField-opp_last_name" },
    { id: 4, name: "Address", description: "Linked To FUB Address" ,elementid:"InputField-opp_address" },
    { id: 13, name: "Address 2", description: "Linked To Address2",elementid:"InputField-opp_address2" },
    { id: 3, name: "Close Date", description: "Linked To Close Date",elementid:"Date-opp_forecasted_close_date" },
    { id: 5, name: "App Description", description: "Linked To App Description ",elementid:"TextArea-App-Description" },
    { id: 6, name: "Commission", description: "Linked To Commission",elementid:"NumberField-commission" },
    { id: 6, name: "Agent Split", description: "Linked To Agent Split",elementid:"NumberField-agent_split" },
    { id: 6, name: "Team Split", description: "Linked To Team Split",elementid:"NumberField-team_split" },
];

export const AppointmentFieldOption=[
  { id: 1, name: "Appointment Date", description: "Linked To Appointment Date",elementid:"Date-opp_appt_date" },
  { id: 2, name: "Appointment Time", description: "Linked To Appointment Time",elementid:"DropDown-fub_appt_start_time" },
  {id: 7,name:"Appointment Location",description:"Linked To Appointment Location",elementid:"DropDown-Appt-Location"},
  { id: 3, name: "Appointment Type", description: "Linked To Appointment Type",elementid:"DropDown-app-type"},

]
export const leadformType=[{
  id: 1, name: "Create Lead Form",value:"create_lead_form",iconInfo:" Create Lead Forms will live outside of follow up boss and will be used to create new leads in follow up boss"
},
{
  id: 2, name: " Update Lead Form",value:"update_lead_form",iconInfo:"Update lead forms will live in each follow up boss lead record and can be used to update that lead."
}]

export const AppointmentFieldValue=[{
  id: 1, name: "Yes appointment form",value:"yes_appt_form"
},
{
  id: 2, name: "Yes appointment disposition form",value:"yes_disp_form"
},
{
  id: 3, name: "No",value:"no"
}]
export const templateTypeOptions = [
  { label: "Whissel Template", value: "whissel_template" },
  { label: "Interface Form", value: "interface_form" }
]

  export const Pagelimit = 10;
