import { useState, useEffect } from "react";
import { ModalFooter } from "./ModalFooter";
import { Tooltip } from "react-tooltip";

const RadioButtonSettings = ({
  elementid,
  board,
  maxLengthofLabel,
  handleCloseModalClick,
  elementSettingsData,
}) => {
  const ElementName = board?.find((item) => String(item?.elementid) === String(elementid))?.name;

  const initialvalue = {
    options: [
      { id: 1, value: "Option 1", isCheck: false },
      { id: 2, value: "Option 2", isCheck: false },
    ],
    label: ElementName,
    size: "large",
    inputType: "radio",
    required: false,
    minValue: "",
    maxValue: "",
    instructions: "",
  };

  const [RadioBtn, setRadioBtn] = useState(
    Object.keys(elementSettingsData)?.length !== 0 ? elementSettingsData : initialvalue
  );
  const [showErrorInFooter, setShowErrorInFooter] = useState(true);

  useEffect(() => {
    const check = RadioBtn.options.slice(1).some((item) => item.value.trim() === "");
    setShowErrorInFooter(check);
  }, [RadioBtn.options]);
  

  const handlerAddNewOption = () => {
    setShowErrorInFooter(true);
    const id = Math.floor(Math.random() * 1000);
    const values = [...RadioBtn.options, { id, value: "", isCheck: false }];
    setRadioBtn({ ...RadioBtn, options: values });
  };

  const handleInputChange = (e) => {
    const { name, type, checked, value } = e.target;
    setRadioBtn({ ...RadioBtn, [name]: type === "checkbox" ? checked : value || ElementName });
  };

  const handleChangeInAddOption = (event, index) => {
    const { name, value } = event.target;
    const updatedOptions = [...RadioBtn.options];
    updatedOptions[index] = { ...updatedOptions[index], [name]: value };
    setRadioBtn({ ...RadioBtn, options: updatedOptions });
  };

  const handleSelectOnlyOne = (selectedIndex) => {
    setRadioBtn((prev) => ({
      ...prev,
      options: prev.options.map((option, index) => ({
        ...option,
        isCheck: index === selectedIndex,
      })),
    }));
  };

  const handleDeleteForOptions = (event, index) => {
    event.preventDefault();
    const values = [...RadioBtn.options];
    values.splice(index, 1);
    setRadioBtn({ ...RadioBtn, options: values });
  };

  return (
    <>
      <div className="modal-body py-4 px-lg-5 p-md-4">
        <h5 className="mb-4">
          <i className="iconify fs-5 pointer me-1" data-icon="tabler:checkbox" />
          Radio Button Field
        </h5>
        <div className="mb-4">
          <label>Radio Button label</label>
          <input
            className="form-control input-bn"
            type="text"
            name="label"
            maxLength={maxLengthofLabel}
            defaultValue={RadioBtn?.label || ElementName}
            onBlur={handleInputChange}
            placeholder="Enter label"
          />
        </div>

        {RadioBtn?.options.map((input, index) => (
          <div key={input.id} className="mb-2">
            <div className="d-flex align-items-center">
              <input
                type="checkbox"
                className="form-check-input me-2"
                checked={input.isCheck}
                onChange={() => handleSelectOnlyOne(index)}
              />
              <input
                className="form-control input-bn"
                type="text"
                defaultValue={input.value}
                name="value"
                placeholder="Enter option"
                onBlur={(event) => handleChangeInAddOption(event, index)}
              />
              <span onClick={(e) => handleDeleteForOptions(e, index)}>
                <i className="iconify fs-5 text-danger pointer ms-2" data-icon="mdi:trash" />
              </span>
            </div>
          </div>
        ))}

        <div className="d-flex align-items-center mt-4">
          <button className="btn btn-primary rounded-pill" onClick={handlerAddNewOption}>
            Add new option
          </button>
        </div>

        <hr className="hr my-5" />
        <h5 className="mb-4">
          <i className="iconify fs-5 pointer me-1" data-icon="bx:slider" />
          General Settings
        </h5>

        <div className="mt-4">
          <div className="mb-4">
            <label>Size</label>
            <select className="form-select input-bn" value={RadioBtn.size} name="size" onChange={handleInputChange}>
              <option value="large">Large</option>
              <option value="medium">Medium</option>
              <option value="small">Small</option>
            </select>
          </div>
        </div>

        <div className="mb-4">
          <label>Options</label>
          <div className="mt-2 d-flex align-items-center">
            <input type="checkbox" className="form-check-input" checked={RadioBtn.required} onChange={handleInputChange} name="required" />
            <label className="form-check-label ms-1">Required</label>
          </div>
        </div>

        <div className="mb-4">
          <textarea
            className="form-control input-bn"
            name="instructions"
            placeholder="Instructions for Users (max 100 char)"
            maxLength="100"
            defaultValue={RadioBtn.instructions}
            onBlur={handleInputChange}
          />
        </div>
      </div>
      <ModalFooter ElementSettingData={RadioBtn} handleCloseModalClick={handleCloseModalClick} elementid={elementid} showErrorInFooter={showErrorInFooter} />
    </>
  );
};

export default RadioButtonSettings;
